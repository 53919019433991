import React from 'react'
import { Link } from 'react-router-dom'
import { Card, Col, Button, Row } from 'react-bootstrap'
import { Grid } from 'gridjs-react'

export default function AdvertiserInvoices() {
    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <Link href="/adv/home" to={'/adv/home'}>
                                    ADV Home
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Invoices
                            </li>
                        </ol>
                        <h2 className="main-title mb-0">Invoices</h2>
                    </div>
                </div>

                <Row className="g-3 justify-content-center">
                    <Col md="12">
                        <Card className="card-one">
                            <Card.Body>
                                <div className="table-responsive">
                                    <Grid
                                        data={[['1', 'ABC Bank', '$35,000.00', 'PAID', '05/08/2023']]}
                                        columns={['ID', 'Campaign', 'Amount', 'Status', 'Date']}
                                        search={true}
                                        pagination={{
                                            enabled: true,
                                            limit: 15
                                        }}
                                        sort={true}
                                        className={{
                                            table: 'table table-hover mb-0'
                                        }}
                                    />
                                </div>
                                <Button href="/invoices/invoice" variant="primary">
                                    Preview Invoice
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                
            </div>
        </React.Fragment>
    )
}
