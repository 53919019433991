import {Dropdown, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useRecentNotifications } from '../../hooks/useRecentNotifications'
import CustomToggle from './CustomToggle'
import NotificationList from './NotificationList'
import { encrypt } from '../../helpers/crypto'
import { GetPubAbbr } from '../../helpers/get-pub-abbr'
import { useSelector } from 'react-redux'

const APV_PUBLISHER_ID = 73;

export default function NotificationsDropdown({ advertiserId }) {
    const { notifications, newNotificationAmount } = useRecentNotifications()
    const encryptedAdvId = encrypt(advertiserId)
    const basePath = encryptedAdvId ? '/adv/notifications' : '/pub/notifications'
    const advParam = encryptedAdvId ? `?advId=${encryptedAdvId}` : ''

    const publisherIdByAbbr = GetPubAbbr();

    const notificationPath = `${basePath}${advParam}`
    const notificationEmailsPath = `${basePath}/emails${advParam}`

    const openPage = url => {
        window.open(url, '_blank')
    }

    const user = useSelector((state) => state.user);
    const isCustomer = user.role === 'CUSTOMER';

    return (
        <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
            {newNotificationAmount > 0 && (
                <Dropdown.Toggle as={CustomToggle}>
                    <small>{newNotificationAmount > 9 ? '9+' : newNotificationAmount}</small>
                    <i className="ri-notification-3-line"></i>
                </Dropdown.Toggle>
            )}
            {newNotificationAmount === 0 && (
                <Dropdown.Toggle as={CustomToggle}>
                    <i className="ri-notification-3-line"></i>
                </Dropdown.Toggle>
            )}
            <Dropdown.Menu className="mt-10-f me--10-f">
                <div className="dropdown-menu-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h6 className="dropdown-menu-title">
                        { publisherIdByAbbr !== APV_PUBLISHER_ID ? 'Notifications' : 'Emails' }
                    </h6>
                    { 
                        !isCustomer && (
                            <Button variant="outline-primary" size="sm" onClick={() => openPage(notificationEmailsPath)}>
                                Mail Center
                            </Button>
                        )
                    }
                </div>
                { publisherIdByAbbr !== APV_PUBLISHER_ID && (
                    <NotificationList notifications={notifications} notificationPath={notificationPath} />
                )}
                { publisherIdByAbbr !== APV_PUBLISHER_ID && (
                    <div className="dropdown-menu-footer">
                        <Link to={notificationPath}>Show all Notifications</Link>
                    </div>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}
