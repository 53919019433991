import React from 'react'
import { useSelector } from 'react-redux'
import { useCookiesContext } from '../contexts/CookiesContext'
import { useRoles } from '../hooks/useRoles'
import NotificationsDropdown from '../components/header/NotificationsDropdown'
import SkinDropdown from '../components/header/SkinDropdown'
import UserProfileDropdown from '../components/header/UserProfileDropdown'

export default function Header() {
    const user = useSelector(state => state.user)
    const { userAccesses } = useRoles(user.role)
    const { getAdvertiserId } = useCookiesContext()
    const advertiserId = getAdvertiserId()

    const toggleSidebar = e => {
        e.preventDefault()
        let isOffset = document.body.classList.contains('sidebar-offset')
        if (isOffset) {
            document.body.classList.toggle('sidebar-show')
        } else {
            if (window.matchMedia('(max-width: 991px)').matches) {
                document.body.classList.toggle('sidebar-show')
            } else {
                document.body.classList.toggle('sidebar-hide')
            }
        }
    }

    return (
        <div className="header-main px-3 px-lg-4">
            <button onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
                <i className="ri-menu-2-fill"></i>
            </button>

            <div className="me-auto"></div>

            <SkinDropdown user={user} userAccesses={userAccesses} />

            <NotificationsDropdown advertiserId={advertiserId} />

            <UserProfileDropdown user={user} userAccesses={userAccesses} advertiserId={advertiserId} />
        </div>
    )
}
