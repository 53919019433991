// import css
import "./assets/css/remixicon.css";

// import rsuite css
import "./scss/rsuite-custom.scss";
import "rsuite/dist/rsuite-no-reset.min.css";

// import toastify css
import "react-toastify/dist/ReactToastify.css";

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import "ag-grid-enterprise";
import {
  ClientSideRowModelModule,
  ModuleRegistry
} from "ag-grid-community";
import { LicenseManager, RowGroupingModule } from "ag-grid-enterprise";
import { CookiesProvider } from "./contexts/CookiesContext";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import RedirectsProvider from './contexts/RedirectsContext';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule
]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <React.Fragment>
          <BrowserRouter>
            <RedirectsProvider>
              <App />
            </RedirectsProvider>
          </BrowserRouter>
          <div className="main-backdrop"></div>
          <ToastContainer />
        </React.Fragment>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>
);

reportWebVitals();
