import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Col, Row } from "react-bootstrap";

import ClusterClicksViews from "./components/auctions/cluster/ClusterClicksViews";
import StatsCardList from "./components/StatsCardList";
import BlockTypes from "./components/BlockTypes";
import GeographicDistribution from "./components/GeographicDistribution";
import { WebsitesTable } from "./components/WebsitesTable";
import IconWithTooltip from "./components/IconWithTooltip";
import { getPublisherInventory } from "../../services/pub";
import { getCampaign } from "../../services/adv";
import PPCManagmentModal from "./components/auctions/modals/PPCManagmentModal";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

export default function ClusterStatistics() {
    const urlParams = new URLSearchParams(window.location.search);
    const inventoryId = urlParams.get("inventoryId");
    const campaignId = urlParams.get("campaignId");
    const advId = urlParams.get("advId");
    const { cookiesData, getPathKey } = useCookiesContext();

    const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === "dark" ? "dark" : "";
    const [campaign, setCampaign] = useState();
    const [inventory, setInventory] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        getPublisherInventory(inventoryId).then((response) => {
            if (response) {
                setInventory(response.data);
            }
        });

        getCampaign(campaignId).then((response) => {
            if (response) {
                setCampaign(response.data);
            }
        });
    }, []);


    const goToAuctionsBack = () => {
        window.location.href = `/adv/campaign/ppc?campaignId=${campaignId}&advId=${advId}`;
    }

    return (
        <React.Fragment>
            <PPCManagmentModal
                show={show}
                handleClose={handleClose}
                inventoryId={inventoryId}
                campaignId={campaignId}
                updatePage={1}
                setUpdatePage={() => {}}
            />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <Link href="#">ADV Home</Link>
                            </li>
                            <li className="breadcrumb-item">
                                {campaign?.name}
                            </li>
                            <li className="breadcrumb-item">
                                PPC Auctions
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {inventory?.name}
                            </li>
                        </ol>
                        <h2 className="main-title mb-0 align-middle" >
                            {inventory?.name} Statistics
                            <Badge
                                bg="outline-secondary"
                                className="me-1 text-secondary"
                                style={{
                                    width: 'max-content',
                                    maxWidth: '180px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    pointerEvents: 'none',
                                    marginLeft: '10px',
                                    verticalAlign: 'middle',
                                }}
                            >
                                Level {inventory?.inventory_hirarchy_level} Cluster
                            </Badge>
                            <IconWithTooltip
                                text={`This is a ${inventory?.inventory_hirarchy_level} level cluster`}
                            />
                        </h2>
                    </div>
                    <div className="d-flex align-items-center justify-content-between" >
                        <Button
                            variant="outline-primary "
                            className="me-2"
                            size="sm"
                            onClick={goToAuctionsBack}
                        >
                            Back to PPC Auctions
                        </Button>
                        <Button
                            variant="primary"
                            size="sm"
                            onClick={() => setShow(true)}
                        >
                            Manage Your Bid
                        </Button>
                    </div>
                </div>
                <Row className="g-3">
                    <Col xl="6">
                        <ClusterClicksViews inventoryId={inventoryId} campaignId={campaignId} skin={currentSkin} />
                    </Col>

                    <Col xl="6">
                        <StatsCardList inventoryId={inventoryId} campaignId={campaignId} skin={currentSkin} />
                    </Col>
                </Row>

                <Row className="mt-3">
                    <Col xl="7" sm='12' style={{ padding: "0 8px" }}>
                        <Col >
                            <BlockTypes inventoryId={inventoryId} campaignId={campaignId} skin={currentSkin}/>
                        </Col>
                        <Col className="mt-3 mb-3">
                            <WebsitesTable inventoryId={inventoryId} campaignId={campaignId} skin={currentSkin}/>
                        </Col>
                    </Col>
                    <Col xl="5" style={{ padding: "0 8px" }}>
                        <GeographicDistribution inventoryId={inventoryId} campaignId={campaignId} skin={currentSkin} />
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}