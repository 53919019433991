import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getRecentNotifications() {
  try {
    const token = getToken();
    axiosInstance.defaults.headers.common["authorization"] = token;
    const response = await axiosInstance.get(
      process.env.REACT_APP_HUB_URL + "/api/notifications/recent"
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
}

export default getRecentNotifications;
