import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function editNotificationSettings(values) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.put(process.env.REACT_APP_HUB_URL + '/api/notifications/settings', values)
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default editNotificationSettings;
