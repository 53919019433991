import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function deleteWebsite(id) {
    try {
        const token = getToken();
        const response = await axiosInstance.delete(`/api/publisher-websites/${id}`, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

export default deleteWebsite;
