import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getAllAdvUsers(account_id) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/users/account', {
            account_id
        }, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

export default getAllAdvUsers;
