import {
    useDispatch
} from "react-redux";
import {
    CookiesKeyNames,
    useCookiesContext
} from "./CookiesContext";
import {
    useNavigate
} from "react-router-dom";
import {
    clearAdvertiser,
    setAdvertiser
} from "../features/advertiser";
import getCampaing from "../services/adv/get_campaign";
import {
    encrypt
} from "../helpers/crypto";
import {
    getPublisher
} from "../services/pub";
import {
    generateUserTokenByAdmin,
    getUserAccount
} from "../services/auth";
import {
    clearUser
} from "../features/user";
import {
    getDefaultPeriods
} from "../helpers/getDefaultPeriods";
import {
    createContext,
    useContext
} from "react";
import { getInventoryCampaign } from "../services/micro";

export const RedirectsContext = createContext();

export default function RedirectsProvider({
    children
}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        cookiesData,
        updateCookiesProperty,
        getPathKey
    } = useCookiesContext();

    const openAdvHomePage = async (loginResponse) => {
        const publisherId = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId] || loginResponse?.publisher_id;
        const token = cookiesData[getPathKey()]?.[CookiesKeyNames.token] || loginResponse?.token;
        const campaignId = loginResponse?.campaign_id;
        const {
            currentPeriod,
            previousPeriod
        } = getDefaultPeriods();

        let campaign = await getCampaing(campaignId);
        if (!campaign.success) {
            const inventory_campaign = await getInventoryCampaign(campaignId);
            const campaign_id = inventory_campaign.data.campaign_id;
            campaign = await getCampaing(Number(campaign_id));
        }

        const advId = campaign.data.account_id;

        getPublisher(publisherId)
            .then((res) => {
                const publisher = res.data;
                
                clearAdvertiser();
                dispatch(setAdvertiser({
                    id: advId
                }));

                updateCookiesProperty(CookiesKeyNames.token, token, advId);
                updateCookiesProperty(CookiesKeyNames.advertiserId, advId, advId);
                updateCookiesProperty(CookiesKeyNames.sidebarSkin, "advertiser", advId);
                updateCookiesProperty(CookiesKeyNames.publisherId, publisher.id, advId);
                updateCookiesProperty(
                    CookiesKeyNames.currentPeriod,
                    currentPeriod,
                    advId
                );
                updateCookiesProperty(
                    CookiesKeyNames.previousPeriod,
                    previousPeriod,
                    advId
                );

                const encryptedAdvId = encrypt(advId);

                const navigateLink =
                    "/adv/home?pub=" +
                    publisher.abbreviation +
                    "&advId=" +
                    encryptedAdvId;

                window.location.href = navigateLink;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const openCampaignAdvAunctions = async (campaignId) => {
        const publisherId = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];
        const {
            currentPeriod,
            previousPeriod
        } = getDefaultPeriods();

        let campaign = await getCampaing(campaignId);
        if (!campaign.success) {
            const inventory_campaign = await getInventoryCampaign(campaignId);
            const campaign_id = inventory_campaign.data.campaign_id;
            campaign = await getCampaing(Number(campaign_id));
        }

        const advId = campaign.data.account_id;

        getPublisher(publisherId)
            .then((res) => {
                const publisher = res.data;
                const api_key = publisher.api_key;
                const old_token = cookiesData[getPathKey()]?.[CookiesKeyNames.token];

                updateCookiesProperty(CookiesKeyNames.publisherToken, old_token);
                
                clearUser();
                clearAdvertiser();
                
                dispatch(setAdvertiser({
                    id: advId
                }));

                updateCookiesProperty(CookiesKeyNames.token, old_token, advId);
                updateCookiesProperty(CookiesKeyNames.advertiserId, advId, advId);
                updateCookiesProperty(CookiesKeyNames.sidebarSkin, "advertiser", advId);
                updateCookiesProperty(CookiesKeyNames.publisherId, publisher.id, advId);
                updateCookiesProperty(
                    CookiesKeyNames.currentPeriod,
                    currentPeriod,
                    advId
                );
                updateCookiesProperty(
                    CookiesKeyNames.previousPeriod,
                    previousPeriod,
                    advId
                );

                const encryptedAdvId = encrypt(advId);

                const navigateLink =
                    "/adv/campaign/ppc?campaignId=" +
                    campaign.data.id +
                    "&pub=" +
                    publisher.abbreviation +
                    "&advId=" +
                    encryptedAdvId;

                window.open(navigateLink, "_blank");

                generateUserTokenByAdmin(api_key)
                    .then((user_token) => {
                        const token = user_token.data.token;
                        clearUser();
                        clearAdvertiser();
                        updateCookiesProperty(CookiesKeyNames.currentPeriod, currentPeriod);
                        updateCookiesProperty(
                            CookiesKeyNames.previousPeriod,
                            previousPeriod
                        );
                        updateCookiesProperty(CookiesKeyNames.token, token, advId);
                        updateCookiesProperty(CookiesKeyNames.advertiserId, advId, advId);
                        updateCookiesProperty(
                            CookiesKeyNames.sidebarSkin,
                            "advertiser",
                            advId
                        );
                        updateCookiesProperty(
                            CookiesKeyNames.publisherId,
                            publisher.id,
                            advId
                        );

                        getUserAccount(advId)
                            .then((account) => {
                                dispatch(setAdvertiser({
                                    id: account.data.id
                                }));
                                updateCookiesProperty(
                                    CookiesKeyNames.advertiserId,
                                    account.data.id,
                                    advId
                                );

                                const navigateLink =
                                    "/adv/home?pub=" +
                                    publisher.abbreviation +
                                    "&advId=" +
                                    account.data.id;

                                window.open(navigateLink, "_blank");
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const openCampaignAdvAnalytics = async (campaignId) => {
        let campaign = await getCampaing(campaignId);
        if (!campaign.success) {
            const inventory_campaign = await getInventoryCampaign(campaignId);
            const campaign_id = inventory_campaign.data.campaign_id;
            campaign = await getCampaing(Number(campaign_id));
        }

        const advId = campaign.data.account_id;

        clearAdvertiser();
        updateCookiesProperty(CookiesKeyNames.sidebarSkin, "advertiser");
        updateCookiesProperty(
            CookiesKeyNames.publisherToken,
            cookiesData[getPathKey()]?.[CookiesKeyNames.token],
            advId
        );
        updateCookiesProperty(
            CookiesKeyNames.publisherId,
            cookiesData["pub"]?.[CookiesKeyNames.publisherId],
            advId
        );

        dispatch(setAdvertiser({
            id: advId
        }));
        updateCookiesProperty(CookiesKeyNames.advertiserId, advId, advId);

        const encryptedAdvId = encrypt(advId);

        window.open(
            "/adv/campaign/analytics?campaignId=" +
            campaign.data.id +
            "&advId=" +
            encryptedAdvId,
            "_blank"
        );
    };

    const openCampaignEdit = async (campaignId) => {
        let campaign = await getCampaing(campaignId);
        if (!campaign.success) {
            const inventory_campaign = await getInventoryCampaign(campaignId);
            const campaign_id = inventory_campaign.data.campaign_id;
            campaign = await getCampaing(Number(campaign_id));
        }

        navigate("/pub/customer/campaign/edit?id=" + campaign.data.id);
    }

    return ( 
        <RedirectsContext.Provider value={
            {
                openCampaignAdvAunctions,
                openCampaignAdvAnalytics,
                openCampaignEdit,
                openAdvHomePage
            }
        }>
            {
                children
            } 
        </RedirectsContext.Provider>
    );
}

export const useRedirectsContext = () => useContext(RedirectsContext);