import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import CustomToggle from './CustomToggle'
import AccountsModal from './AccountsModal'

import { CookiesKeyNames, useCookiesContext } from '../../contexts/CookiesContext'
import { getMe } from '../../services/micro'
import { setUser } from '../../features/user'
import { switchSkin } from '../../helpers/switch-skin'

export default function SkinDropdown({ user, userAccesses }) {
    const [modalShow, setModalShow] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { cookiesData, updateCookiesProperty, getPathKey, getToken, getSkinMode, getPublisherId, isDarkMode, isAdvertiser } = useCookiesContext()

    const handleSkinMode = skin => {
        updateCookiesProperty(CookiesKeyNames.skinMode, skin, getPathKey())

        const HTMLTag = document.querySelector('html')
        if (skin === 'dark') {
            HTMLTag.setAttribute('data-skin', skin)
            switchSkin(skin)
        } else {
            HTMLTag.removeAttribute('data-skin')
            HTMLTag.removeAttribute('data-sidebar')
            switchSkin('')
        }
    }

    const openMailCatcher = () => {
        window.open('https://mailcatcher.revbox.co/')
    }

    const openAdminPanel = () => {
        const token = getToken()
        const skin = getSkinMode()

        window.open('https://admin.revbox.co?token=' + token + '&skin=' + skin, '_blank')
    }

    const systemMode = (buttonName) => {
        const HTMLTag = document.querySelector('html')

        HTMLTag.removeAttribute('data-sidebar')

        if (buttonName !== undefined && buttonName !== 'advertiser') {
            HTMLTag.setAttribute('data-sidebar', buttonName)
            updateCookiesProperty(CookiesKeyNames.sidebarSkin, buttonName)
        }

        if (buttonName === 'advertiser') {
            setModalShow(true)
        } else {
            const publisher_token = cookiesData[getPathKey()]?.[CookiesKeyNames.publisherToken]
            const publisher_id = getPublisherId()

            getMe(publisher_token)
                .then(res => {
                    dispatch(setUser(res.data))

                    updateCookiesProperty(CookiesKeyNames.token, publisher_token, 'pub')
                    updateCookiesProperty(CookiesKeyNames.publisherId, publisher_id, 'pub')

                    setModalShow(false)

                    if (user.role !== 'developer' && user.role !== 'content_manager' && user.role !== 'sales') {
                        navigate('/pub/dashboard/revenue?pub=' + publisher_id)
                    } else {
                        navigate('/pub/inventory/queue?pub=' + publisher_id)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    return (
        <>
            <Dropdown className="dropdown-skin" align="end">
                <Dropdown.Toggle as={CustomToggle}>
                    <i className="ri-settings-3-line"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-10-f header-dropdown-menu">
                    <label>Skin Mode</label>
                    <nav className="nav nav-skin">
                        <button onClick={() => handleSkinMode('light')} className={isDarkMode() ? 'nav-link' : 'nav-link active'}>
                            Light
                        </button>
                        <button onClick={() => handleSkinMode('dark')} className={isDarkMode() ? 'nav-link active' : 'nav-link'}>
                            Dark
                        </button>
                    </nav>
                    {isAdvertiser() && user.role != 'CUSTOMER' && (
                        <>
                            <hr />
                            <label>System Mode</label>
                            <nav id="systemMode" className="nav nav-skin">
                                {user.role === 'ADMIN' && (
                                    <button onClick={openAdminPanel} className="nav-link">
                                        Admin
                                    </button>
                                )}

                                <button onClick={() => systemMode('publisher')} className="nav-link">
                                    Publisher
                                </button>

                                {(user.role === 'PUBLISHER' || user.role === 'ADMIN') && (
                                    <button onClick={() => systemMode('advertiser')} className={isAdvertiser() ? 'nav-link active' : 'nav-link'}>
                                        Advertiser
                                    </button>
                                )}
                            </nav>
                        </>
                    )}
                    {!isAdvertiser() && (
                        <>
                            <hr />
                            <label>System Mode</label>
                            <nav id="systemMode" className="nav nav-skin">
                                <button className="nav-link active">Publisher</button>
                                {userAccesses?.includes('advertiser_access') && (
                                    <button onClick={() => systemMode('advertiser')} className={isAdvertiser() ? 'nav-link active' : 'nav-link'}>
                                        Advertiser
                                    </button>
                                )}
                            </nav>
                        </>
                    )}
                    {
                        user.role === 'ADMIN' && (
                            <>
                                <hr />
                                <label>Admin Tools</label>
                                <nav id="systemMode" className="nav nav-skin">
                                    <button onClick={openMailCatcher} className="nav-link">
                                        Mail Catcher
                                    </button>
                                    <button onClick={openAdminPanel} className="nav-link">
                                        Block Library
                                    </button>
                                </nav>
                            </>
                    )}
                </Dropdown.Menu>
            </Dropdown>

            <AccountsModal
                modalShow={modalShow}
                user={user}
                navigate={navigate}
                dispatch={dispatch}
                setModalShow={setModalShow}
            />
        </>
    )
}
