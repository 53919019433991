import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function editPublisherInventoryAltVersion(id, data) {
    try {
        const token = getToken();
        const response = await axiosInstance.put(`/api/publisher-inventory-alt-versions/${id}`, data, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

export default editPublisherInventoryAltVersion;
