import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function getAllRoles(advId = "") {
  try {
    const token = getToken(advId);
    axiosInstance.defaults.headers.common["authorization"] = token;
    const response = await axiosInstance.get(
      process.env.REACT_APP_HUB_URL + "/api/roles/all"
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
}

export default getAllRoles;
