import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Button, Dropdown, Form, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getEmailTemplate,
  getEmailTemplatesAndStyles,
  getPublisherAndAssignEmails,
  getPublisherEmails,
  loadImages,
  updateEmailCustomization,
} from "../services/micro";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

const formatTime = (time) => {
  const date = new Date(time);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  return `${formattedHours}:${formattedMinutes}${ampm}`;
};

function formatTimestampToTime(timestamp) {
  const date = new Date(parseInt(timestamp, 10));

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const ampm = hours >= 12 ? "pm" : "am";

  return `${formattedHours}:${formattedMinutes}${ampm}`;
}

const formattedDate = (date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  if (date === today.toLocaleDateString()) {
    return "Today";
  }

  if (date === yesterday.toLocaleDateString()) {
    return "Yesterday";
  }

  return date;
};

function groupByDate(emails) {
  const grouped = emails.reduce((acc, email) => {
    const date = new Date(email.sent_at).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = [];
    }

    const newEmail = {
      id: email.id,
      unread: email.is_read_by_user,
      publisher_id: email.publisher_id,
      initial: {
        letter: email?.sent_to?.first_name
          ? email.sent_to.first_name[0].toUpperCase()
          : "",
        bg: "primary",
      },
      type: email.type,
      sender: email.sent_to.email,
      star: "line",
      time: email.time
        ? formatTimestampToTime(email.time)
        : formatTime(email.sent_at),
      date: formattedDate(date),
      subject: email.subject,
      html: email.html_file_url,
      receiver_first_name: email.sent_to.first_name,
      receiver_last_name: email.sent_to.last_name,
      campaing_name: email.campaing_name,
      summary: "",
    };

    acc[date].push(newEmail);
    return acc;
  }, {});

  return Object.keys(grouped).map((date) => ({
    date: formattedDate(date),
    emails: grouped[date],
  }));
}

function getPublisherEmailById(id) {
  if (id === 1 || id === 34) {
    return "no-reply@revbox.co";
  }

  if (id === 67 || id === 10) {
    return "notifications@hoteltechreport.com";
  }

  return "no-reply@revbox.co";
}

export default function Email() {
  const { cookiesData, getPathKey } = useCookiesContext();
  const [tab, setTab] = useState("my-emails");
  const [publisherTemplates, setPublisherTemplates] = useState([]);
  const [advertiserTemplates, setAdvertiserTemplates] = useState([]);
  const [customization, setCustomization] = useState({});
  const [emailType, setEmailType] = useState("");
  const [emailHTMLView, setEmailHTMLView] = useState("");
  const [emailHTMLCopy, setEmailHTMLCopy] = useState("");
  const [subject, setSubject] = useState("");
  const [emails, setEmails] = useState([]);
  const [emailsCount, setEmailsCount] = useState(0);
  const [groupedEmails, setGroupedEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;

  const advertiserId =
    cookiesData[getPathKey()]?.[CookiesKeyNames.advertiserId];

  const basePath = advertiserId ? "/adv/notifications" : "/pub/notifications";
  const advParam = advertiserId ? `?advId=${advertiserId}` : "";

  const notificationPath = `${basePath}${advParam}`;

  const saveCustomization = () => {
    window.confirm("Are you sure you want to save the changes?") &&
      updateEmailCustomization(customization)
        .then((res) => {
          toast.success("Customization saved successfully!");
        })
        .catch((err) => {
          toast.error("Failed to save customization!");
        });
  };

  const getData = (offsetItem) => {
    let currentOffset = offset || 0;

    if (offsetItem != undefined && offsetItem != null) {
      currentOffset = offsetItem + 1;
    }

    const fetchEmails = (page, type) => {
      const params = {
        page: page,
        is_read_by_user: false,
        search,
        pagination_limit: limit,
      };

      const fetchFunction =
        type === "my-emails" ? getPublisherEmails : getPublisherAndAssignEmails;

      return fetchFunction(
        params,
        type === "all-pub-emails" ? "publisher" : "advertiser"
      );
    };

    fetchEmails(currentOffset, tab)
      .then((res) => {
        if (res && res.data && Array.isArray(res.data.emails)) {
          const newEmails = emails.concat(res.data.emails);
          const uniqueArrayOfEmails = Array.from(
            new Map(newEmails.map((item) => [item.id, item])).values()
          );

          setEmails(uniqueArrayOfEmails);
          setEmailsCount(res.data.count);
          setGroupedEmails(groupByDate(uniqueArrayOfEmails));
          setShowTemplateContent(false);
          setShowCustomize(false);

          const totalEmailsLoaded = uniqueArrayOfEmails.length;
          const totalEmailsAvailable = res.data.count;

          if (totalEmailsLoaded === 0 && totalEmailsAvailable > 0) {
            setHasMore(false);
            return;
          }

          setHasMore(totalEmailsLoaded < totalEmailsAvailable);

          if (totalEmailsLoaded > 0) {
            const email = groupByDate(uniqueArrayOfEmails)[0].emails[0];
            setSelectedEmail(email);
            setShowContent(true);
            setEmailActive("00");
          }

          setOffset(currentOffset + 1);
        } else {
          console.error("Unexpected response structure:", res);
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log("Error fetching emails:", err);
        setHasMore(false);
      });
  };

  useEffect(() => {
    setOffset(0);
    setEmails([]);
    setGroupedEmails([]);

    if (tab === "my-emails") {
      getData(0);
    }

    if (tab === "all-pub-emails") {
      getData(0);
    }

    if (tab === "all-adv-emails") {
      getData(0);
    }

    if (tab === "pub-templates") {
      if (window.innerWidth < 768) {
        setShowTemplateContent(false);
      } else {
        setShowTemplateContent(true);
      }

      setShowCustomize(true);
    }

    if (tab === "adv-templates") {
      if (window.innerWidth < 768) {
        setShowTemplateContent(false);
      } else {
        setShowTemplateContent(true);
      }

      setShowCustomize(true);
    }
  }, [tab, search]);

  const handleTab = (newTab) => {
    if (newTab !== tab) {
      if (newTab === "pub-templates") {
        setEmailType(publisherTemplates[0].type);
      } else if (newTab === "adv-templates") {
        setEmailType(advertiserTemplates[0].type);
      }

      setTab(newTab);
      setOffset(0);
      setHasMore(true);
      setEmails([]);
      setGroupedEmails([]);
    }
  };

  const switchEmail = (currentEmail, direction) => {
    let newEmail = null;
    let groupIndex = 0;
    let emailIndex = 0;

    groupedEmails.findIndex((group, gIndex) =>
      group.emails.find((email, eIndex) => {
        if (email.id === currentEmail.id) {
          groupIndex = gIndex;
          emailIndex = eIndex;
          return true;
        }

        return false;
      })
    );

    if (direction === "prev") {
      if (emailIndex === 0) {
        if (groupIndex === 0) {
          groupIndex = groupedEmails.length - 1;
        } else {
          groupIndex--;
        }

        emailIndex = groupedEmails[groupIndex].emails.length - 1;
      } else {
        emailIndex--;
      }
    }

    if (direction === "next") {
      if (emailIndex === groupedEmails[groupIndex].emails.length - 1) {
        if (groupIndex === groupedEmails.length - 1) {
          groupIndex = 0;
        } else {
          groupIndex++;
        }

        emailIndex = 0;
      } else {
        emailIndex++;
      }
    }

    newEmail = groupedEmails[groupIndex].emails[emailIndex];

    setSelectedEmail(newEmail);
    setEmailActive(groupIndex.toString() + emailIndex.toString());
  };

  const handleChooseTemplate = (templateType) => {
    setEmailType(templateType);
  };

  const handleCustomize = async (key, value) => {
    if (key.includes("logo")) {
      if (value === "") {
        setCustomization({
          ...customization,
          [key]: value,
        });
        return;
      }

      const imagesResponse = await loadImages(value.target.files);

      if (imagesResponse) {
        setCustomization({
          ...customization,
          [key]: imagesResponse.data[0].url,
        });
      }
    } else {
      setCustomization({
        ...customization,
        [key]: value,
      });
    }
  };

  const editTemplate = (emailType) => {
    if (emailType === "") return;
    if (Object.keys(customization).length === 0) return;
    getEmailTemplate(customization, emailType).then((res) => {
      if (res.data === "") return;
      setEmailHTMLView(res.data.emailHtml);
      setSubject(res.data.subject);

      if (publisherTemplates.find((template) => template.type === emailType)) {
        setTab("pub-templates");
      } else {
        setTab("adv-templates");
      }
    });
  };

  useEffect(() => {
    if (emailType === "") return;
    if (Object.keys(customization).length === 0) return;

    getEmailTemplate(customization, emailType).then((res) => {
      if (res.data === "") return;
      setEmailHTMLView(res.data.emailHtml);
      setSubject(res.data.subject);
    });
  }, [emailType, customization]);

  useEffect(() => {
    document.body.classList.add("page-app");
    document.body.classList.add("sidebar-offset");

    getEmailTemplatesAndStyles().then((res) => {
      const data = res.data;
      setPublisherTemplates(data.publisher_templates);
      setAdvertiserTemplates(data.advertiser_templates);
      setCustomization(data.customization);
    });

    return () => {
      document.body.classList.remove("page-app");
      document.body.classList.remove("sidebar-offset");
    };
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="header-link"
    >
      {children}
    </Link>
  ));

  const [showTemplateContent, setShowTemplateContent] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showCustomize, setShowCustomize] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [emailActive, setEmailActive] = useState(null);

  return (
    <React.Fragment>
      <div
        className={
          "main main-email" +
          (showContent ? " show-content" : "") +
          (showSidebar ? " show" : "")
        }
      >
        <PerfectScrollbar className="email-sidebar" style={{ paddingTop: 0 }}>
          <div className="mail-center-header pt-3 mt-1">
            <h5 className="mb-0">Mail Center</h5>
          </div>

          <hr />

          <Nav className="nav-sidebar">
            <Nav.Link
              href=""
              className="w-100"
              onClick={() => {
                window.location.href = notificationPath;
              }}
            >
              <i className="ri-arrow-left-line"></i>
              Notifications
            </Nav.Link>
          </Nav>

          <hr />

          <label className="sidebar-label mb-2 mt-4">Email Feeds</label>
          <Nav className="nav-sidebar">
            <Nav.Link
              href=""
              className={tab === "my-emails" ? "active" : ""}
              onClick={() => handleTab("my-emails")}
            >
              <i className="ri-inbox-fill"></i> Inbox
            </Nav.Link>
            <Nav.Link
              href=""
              className={tab === "all-pub-emails" ? "active" : ""}
              onClick={() => handleTab("all-pub-emails")}
            >
              <i className="ri-mail-send-line"></i> PUB Feed
            </Nav.Link>
            <Nav.Link
              href=""
              className={tab === "all-adv-emails" ? "active" : ""}
              onClick={() => handleTab("all-adv-emails")}
            >
              <i className="ri-mail-send-line"></i> ADV Feed
            </Nav.Link>
          </Nav>

          <hr />

          <label className="sidebar-label mb-2">Customization</label>
          <Nav className="nav-sidebar">
            <Nav.Link
              href=""
              className={tab === "pub-templates" ? "active" : ""}
              onClick={() => handleTab("pub-templates")}
            >
              <i className="ri-draft-line"></i> PUB Templates
            </Nav.Link>
            <Nav.Link
              href=""
              className={tab === "adv-templates" ? "active" : ""}
              onClick={() => handleTab("adv-templates")}
            >
              <i className="ri-draft-line"></i> ADV Templates
            </Nav.Link>
          </Nav>
        </PerfectScrollbar>

        {(tab === "my-emails" ||
          tab === "all-pub-emails" ||
          tab === "all-adv-emails") && (
          <PerfectScrollbar className="email-list">
            <div className="email-list-header pe-3">
              <Link
                href=""
                className="email-menu"
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <i className="ri-menu-2-fill"></i>
              </Link>
              <h5 className="mb-0">
                Inbox{" "}
                {tab === "all-pub-emails"
                  ? " - PUB Feed"
                  : tab === "all-adv-emails"
                  ? " - ADV Feed"
                  : "- My Emails"}
              </h5>
            </div>
            <div className="email-list-header pe-3">
              <Form.Control
                type="text"
                placeholder="Search by recipient email…"
                value={search}
                onChange={(e) => setSearch(e.target.value || "")}
              />
            </div>
            <InfiniteScroll
              key={search.length}
              dataLength={emails.length}
              next={getData}
              hasMore={hasMore}
              loader={<></>}
              initialScrollY={0}
              style={{
                overflowX: "hidden",
              }}
              height={window.innerHeight - 190}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  {/* <b>No new emails</b> */}
                </p>
              }
            >
              {groupedEmails.map((group, index) => (
                <React.Fragment key={index}>
                  <label className="email-list-label">{group.date}</label>
                  {group.emails.map((email, ind) => (
                    <div
                      className={`email-item${email.unread ? " unread" : ""}${
                        emailActive === index.toString() + ind.toString()
                          ? " selected"
                          : ""
                      }`}
                      key={index.toString() + ind.toString()}
                      onClick={(e) => {
                        setShowContent(true);
                        setSelectedEmail(email);
                        setEmailActive(index.toString() + ind.toString());
                        setShowSidebar(false);
                      }}
                    >
                      <div className="avatar">
                        {email.avatar && <img src={email.avatar} alt="" />}
                        {email.initial && (
                          <span
                            className={`avatar-initial bg-${email.initial.bg}`}
                          >
                            {email.initial.letter}
                          </span>
                        )}
                      </div>
                      <div className="email-item-body">
                        <div className="d-flex align-items-center mb-1">
                          <span className="email-item-sender">
                            {email.sender}
                          </span>
                          <span className="email-item-date">{email.time}</span>
                        </div>
                        <h6 className="email-item-subject">{email.subject}</h6>
                        <p className="email-item-summary">{email.summary}</p>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </InfiniteScroll>
          </PerfectScrollbar>
        )}

        {(tab === "my-emails" ||
          tab === "all-pub-emails" ||
          tab === "all-adv-emails") && (
          <PerfectScrollbar
            className={
              "email-content" + (showContent && selectedEmail ? " show" : "")
            }
          >
            {/* add div above with position absolute to be able to scroll*/}
            <div className="email-content-header">
              <Nav
                as="nav"
                className="d-flex justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center">
                  <Nav.Link
                    href="#"
                    className="d-xl-none"
                    onClick={() => setShowContent(false)}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </Nav.Link>
                  <span className="fs-sm text-secondary">
                    {selectedEmail && selectedEmail.date}
                  </span>
                  <span className="fs-sm text-secondary ms-1">at</span>
                  <span className="fs-xs text-secondary ms-1">
                    {selectedEmail && selectedEmail.time}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <Nav.Link
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      switchEmail(selectedEmail, "prev");
                    }}
                  >
                    <i className="ri-arrow-left-s-line"></i>
                  </Nav.Link>
                  <Nav.Link
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      switchEmail(selectedEmail, "next");
                    }}
                  >
                    <i className="ri-arrow-right-s-line"></i>
                  </Nav.Link>
                </div>
              </Nav>
            </div>
            <div className="email-content-empty" style={{ fontWeight: 400 }}>
              No email has been selected
            </div>
            <div className="email-content-body h-100">
              <div className="email-msg-item h-100">
                <div className="d-flex align-items-center fs-sm p-4">
                  {selectedEmail && (
                    <div className="avatar" style={{ marginRight: 10 }}>
                      {selectedEmail.avatar && (
                        <img src={selectedEmail.avatar} alt="" />
                      )}
                      {selectedEmail.initial && (
                        <span
                          className={
                            "avatar-initial bg-" + selectedEmail.initial.bg
                          }
                        >
                          {selectedEmail.initial.letter}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="me-auto">
                    <p className="mb-0">
                      <strong>System </strong>
                      <span className="text-secondary">
                        &lt;{getPublisherEmailById(selectedEmail?.publisher_id)}
                        &gt;
                      </span>
                    </p>
                    <p className="mb-0">
                      <span className="text-secondary">To: </span>
                      <strong>
                        {selectedEmail && selectedEmail.receiver_first_name}
                      </strong>
                      <strong style={{ marginLeft: 2 }}>
                        {selectedEmail && selectedEmail.receiver_last_name}
                      </strong>
                      <span style={{ marginLeft: 5 }}>
                        {selectedEmail && selectedEmail.sender}
                      </span>
                      {selectedEmail &&
                        selectedEmail.campaing_name &&
                        tab === "all-adv-emails" && (
                          <div className="badge badge-outline-primary ms-2">
                            {selectedEmail.campaing_name}
                          </div>
                        )}
                    </p>
                  </div>
                  {selectedEmail && (
                    <Nav className="nav-icon ms-3">
                      <Dropdown align="end" className="ms-auto">
                        <Dropdown.Toggle as={CustomToggle}>
                          <i className="ri-more-2-fill"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => editTemplate(selectedEmail.type)}
                          >
                            Edit Template
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Nav>
                  )}
                </div>

                <h4 className="text-dark" style={{ padding: "0 0  20px 25px" }}>
                  {selectedEmail && selectedEmail.subject}
                </h4>

                {selectedEmail && selectedEmail.html && (
                  <iframe
                    src={selectedEmail.html}
                    title="email"
                    className="w-100"
                    style={{
                      height: "75%",
                    }}
                  ></iframe>
                )}
              </div>
            </div>
          </PerfectScrollbar>
        )}

        {(tab === "pub-templates" || tab === "adv-templates") &&
          showCustomize && (
            <PerfectScrollbar
              className={`custom-email-list ${showCustomize ? "show" : ""}`}
            >
              <div className="email-list-header pe-3">
                <Link
                  href=""
                  className="email-menu"
                  onClick={() => setShowSidebar(!showSidebar)}
                >
                  <i className="ri-menu-2-fill"></i>
                </Link>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h5 className="mb-0">
                    {tab === "pub-templates"
                      ? "Publisher Templates"
                      : "Advertiser Templates"}
                  </h5>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className="btn-customize"
                    onClick={() => saveCustomization()}
                  >
                    Save Changes
                  </Button>
                </div>
                <Button
                  variant="outline-primary"
                  size="sm"
                  style={{ marginLeft: 10, width: 175 }}
                  className="d-xl-none align-items-center d-flex"
                  onClick={() => {
                    setShowSidebar(false);
                    setShowCustomize(false);
                    setShowTemplateContent(true);
                  }}
                >
                  Preview Template
                </Button>
              </div>
              {customization && (
                <Form className="p-3">
                  <div>
                    <h5 className="mb-3">General</h5>
                    <span className="fs-sm text-secondary">
                      These email style changes will be implemented across all
                      templates, ensuring a consistent and unified appearance
                      throughout every email communication sent from the system.
                    </span>
                    <hr />
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label>Header Background Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["dark-blue"]}
                      onChange={(e) =>
                        handleCustomize("dark-blue", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Tile Background Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["tile-background-color"]}
                      onChange={(e) =>
                        handleCustomize("tile-background-color", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Body Background Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization.white}
                      onChange={(e) => handleCustomize("white", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Primary Button Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization.primary}
                      onChange={(e) =>
                        handleCustomize("primary", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Footer Address</Form.Label>
                    <Form.Control
                      type="text"
                      value={customization["footer-address"]}
                      onChange={(e) =>
                        handleCustomize("footer-address", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Footer Company</Form.Label>
                    <Form.Control
                      type="text"
                      value={customization["footer-company"]}
                      onChange={(e) =>
                        handleCustomize("footer-company", e.target.value)
                      }
                    />
                  </Form.Group>

                  {tab === "pub-templates" && (
                    <div className="mt-4">
                      <hr />
                      <h5 className="mb-3">Your Branding</h5>
                      <hr />
                    </div>
                  )}

                  {tab === "pub-templates" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Publisher Header Logo</Form.Label>
                      {customization["header-logo-dark"] ? (
                        <div>
                          <img
                            src={customization["header-logo-dark"]}
                            alt=""
                            style={{ height: "30px", border: "1px solid #ccc" }}
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            variant="outline-danger"
                            size="sm"
                            onClick={() =>
                              handleCustomize("header-logo-dark", "")
                            }
                          >
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <Form.Control
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) =>
                            handleCustomize("header-logo-dark", e)
                          }
                        />
                      )}
                    </Form.Group>
                  )}
                  {tab === "pub-templates" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Publisher Footer Logo</Form.Label>
                      {customization["footer-logo"] ? (
                        <div>
                          <img
                            src={customization["footer-logo"]}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              border: "1px solid #ccc",
                            }}
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleCustomize("footer-logo", "")}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <Form.Control
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => handleCustomize("footer-logo", e)}
                        />
                      )}
                    </Form.Group>
                  )}
                  {/* { (tab === "pub-templates") &&
                  <Form.Group className="mb-3">
                    <Form.Label>Publisher Text Color Dark</Form.Label>
                    <Form.Control type="color" value={customization["text-color-dark"]} onChange={(e) => handleCustomize("text-color-dark", e.target.value)} />
                  </Form.Group>
                } */}

                  {tab === "adv-templates" && (
                    <div className="mt-4">
                      <hr />
                      <h5 className="mb-3">Your Branding</h5>
                      <hr />
                    </div>
                  )}
                  {tab === "adv-templates" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Advertiser Header Logo</Form.Label>
                      {customization["header-logo"] ? (
                        <div>
                          <img
                            src={customization["header-logo"]}
                            alt=""
                            style={{ height: "30px", border: "1px solid #ccc" }}
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleCustomize("header-logo", "")}
                          >
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <Form.Control
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) => handleCustomize("header-logo", e)}
                        />
                      )}
                    </Form.Group>
                  )}
                  {tab === "adv-templates" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Advertiser Footer Logo</Form.Label>
                      {customization["footer-logo-light"] ? (
                        <div>
                          <img
                            src={customization["footer-logo-light"]}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              border: "1px solid #ccc",
                            }}
                          />
                          <Button
                            style={{ marginLeft: 10 }}
                            variant="outline-danger"
                            size="sm"
                            onClick={() =>
                              handleCustomize("footer-logo-light", "")
                            }
                          >
                            Remove
                          </Button>
                        </div>
                      ) : (
                        <Form.Control
                          type="file"
                          accept=".png,.jpg,.jpeg"
                          onChange={(e) =>
                            handleCustomize("footer-logo-light", e)
                          }
                        />
                      )}
                    </Form.Group>
                  )}
                  <div className="mt-4">
                    <hr />
                    <h5 className="mb-3">Others Colors</h5>
                    <hr />
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label>Body Text Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["text-color"]}
                      onChange={(e) =>
                        handleCustomize("text-color", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Footer Text Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["footer-text-color"]}
                      onChange={(e) =>
                        handleCustomize("footer-text-color", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Subtitle Text Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["small-text-gray"]}
                      onChange={(e) =>
                        handleCustomize("small-text-gray", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Success Percent Green, %</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["percent-green"]}
                      onChange={(e) =>
                        handleCustomize("percent-green", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Danger Percent Red, %</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["percent-red"]}
                      onChange={(e) =>
                        handleCustomize("percent-red", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Warning Percent Yellow, %</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["percent-yellow"]}
                      onChange={(e) =>
                        handleCustomize("percent-yellow", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>General Warning Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization.warning}
                      onChange={(e) =>
                        handleCustomize("warning", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Danger Alert Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization.alert}
                      onChange={(e) => handleCustomize("alert", e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Separator Line Color</Form.Label>
                    <Form.Control
                      type="color"
                      value={customization["border-color"]}
                      onChange={(e) =>
                        handleCustomize("border-color", e.target.value)
                      }
                    />
                  </Form.Group>
                </Form>
              )}
            </PerfectScrollbar>
          )}

        {(tab === "pub-templates" || tab === "adv-templates") &&
          showTemplateContent && (
            <PerfectScrollbar
              className={
                "custom-email-content" + (showTemplateContent ? " show" : "")
              }
            >
              <div className="email-content-header">
                <Nav
                  as="nav"
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex align-items-center">
                    <Nav.Link
                      href="#"
                      id="back-arrow-to-customize"
                      className="d-xl-none"
                      onClick={() => {
                        setShowTemplateContent(false);
                        setShowCustomize(true);
                      }}
                    >
                      <i className="ri-arrow-left-line"></i>
                    </Nav.Link>
                    {!document.getElementById("back-arrow-to-customize") && (
                      <h5 className="mb-0">Template Preview</h5>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    {tab === "pub-templates" && (
                      <select
                        className="form-select form-select-sm"
                        style={{ width: "200px" }}
                        onChange={(e) => handleChooseTemplate(e.target.value)}
                      >
                        {publisherTemplates.map((template, index) => (
                          <option key={index} value={template.type}>
                            {template.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {tab === "adv-templates" && (
                      <select
                        className="form-select form-select-sm"
                        style={{ width: "200px" }}
                        onChange={(e) => handleChooseTemplate(e.target.value)}
                      >
                        {advertiserTemplates.map((template, index) => (
                          <option key={index} value={template.type}>
                            {template.name}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </Nav>
              </div>
              {emailHTMLView !== "" ? (
                <iframe
                  srcdoc={emailHTMLView}
                  title="email"
                  className="w-100"
                  style={{
                    height: "95%",
                  }}
                ></iframe>
              ) : (
                <div className="email-content-empty">
                  No email has been selected
                </div>
              )}
            </PerfectScrollbar>
          )}
      </div>
    </React.Fragment>
  );
}
