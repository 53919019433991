import { getCampaign, getCustomer } from "../services/adv";
import { getTransaction, getBillingMethod } from "../services/finance";
import { getInventoryCampaign, getUser } from "../services/micro";
import getClientApplicationById from "../services/micro/get_client_application_by_id";
import {
  getCategory,
  getWebsite,
  getPublisherInventory,
} from "../services/pub";

async function getRecord(id, table_name) {
  try {
    if (!table_name) {
      throw new Error("Table name is required");
    }

    const tableHandlers = {
      publisher_categories: getCategory,
      publisher_websites: getWebsite,
      publisher_inventory: getPublisherInventory,
      clients_accounts: getCustomer,
      client_campaigns: getCampaign,
      users: getUser,
      inventory_campaigns: getInventoryCampaign,
      transactions: getTransaction,
      billing_methods: getBillingMethod,
      client_applications: getClientApplicationById,
    };

    const handler = tableHandlers[table_name];
    if (!handler) {
      throw new Error(`Unknown table name: ${table_name}`);
    }
    return await handler(id);
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default getRecord;
