import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function readNotifications(notifications_ids) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/notifications/read', {
            notifications_ids
        })

        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default readNotifications;
