import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { getServerSideDatasourceClusterPPC } from "../../../../../helpers/get-serverside-datasource-cluster-ppc";
import {
  ClusterNameCellTooltipRenderer,
  ClusterNameColumnHeaderTooltipRenderer,
  ClusterNameRenderer,
} from "./ClusterNameRenderer";
import {
  ClusterActionCellTooltipRenderer,
  ClusterActionRenderer,
} from "./ClusterActionRenderer";
import {
  ClusterPositionCellTooltipRenderer,
  ClusterPositionRenderer,
  ClusterPositionColumnHeaderTooltipRenderer
} from "./ClusterPositionRenderer";
import { StatusCellTooltipRenderer, StatusRenderer, StatusColumnHeaderTooltipRenderer } from "./ClusterStatusRenderer";
import {
  ClusterYourBidCellColumnHeaderTooltipRenderer,
  ClusterYourBidCellRenderer,
  ClusterYourBidCellTooltipRenderer,
} from "./ClusterYourBidRenderer";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../../../../../scss/components/_ag-table.scss";
import "ag-grid-charts-enterprise";
import { TopBidCellColumnHeaderTooltipRenderer, TopBidCellTooltipRenderer, TopBidCellRenderer } from "./ClusterTopBidRenderer";
import { MissedClicksForecastCellColumnHeaderTooltipRenderer, MissedClicksForecastCellTooltipRenderer, MissedClicksForecastRenderer } from "./ClusterMissedClicksForecastRenderer";

const customColumns = [
  {
    field: "Topic",
    cellRenderer: ClusterNameRenderer,
    headerTooltip: ClusterNameColumnHeaderTooltipRenderer(),
    tooltipValueGetter: ClusterNameCellTooltipRenderer,
    rowGroup: true,
    hide: true,
  },
  {
    field: "Position",
    cellRenderer: ClusterPositionRenderer,
    headerTooltip: ClusterPositionColumnHeaderTooltipRenderer(),
    tooltipValueGetter: ClusterPositionCellTooltipRenderer,
  },
  {
    field: "Status",
    cellRenderer: StatusRenderer,
    headerTooltip: StatusColumnHeaderTooltipRenderer(),
    tooltipValueGetter: StatusCellTooltipRenderer,
  },
  {
    field: "Your Bid",
    cellRenderer: ClusterYourBidCellRenderer,
    headerTooltip: ClusterYourBidCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: ClusterYourBidCellTooltipRenderer,
  },
  {
    field: "Top Bid",
    cellRenderer: TopBidCellRenderer,
    headerTooltip: TopBidCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: TopBidCellTooltipRenderer,
  },
  {
    field: "Action",
    cellRenderer: ClusterActionRenderer,
    headerTooltip: "Action",
    tooltipValueGetter: ClusterActionCellTooltipRenderer,
  },
  {
    field: "Missed Clicks Forecast",
    cellRenderer: MissedClicksForecastRenderer,
    headerTooltip: MissedClicksForecastCellColumnHeaderTooltipRenderer(),
    tooltipValueGetter: MissedClicksForecastCellTooltipRenderer,
  },
];

const containerStyle = {
  width: "100%",
  height: "620px",
}

const gridStyle = {
  width: "100%",
  height: "100%",
}

const defaultColDef = {
  flex: 1,
  minWidth: 90,
};


export const ClusterPPCAuctions = ({
  getData,
  skin,
  onClick,
  campaignId,
  inventoryCampaign,
  updatePage,
  publisher,
  publisherId,
  setClusterActiveAuctions,
  setClusterInheritedAuctionsAndNotShown,
  setAnyClusterAuctions,
  setNumberOfClusterAuctions,
  display
}) => {
  const [search, setSearch] = useState("");
  const [columnDefs, setColumnDefs] = useState([]);
  const [data, setData] = useState([]);
  const getDataPath = useCallback((data) => data.path, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Topic",
      field: "name",
      pinned: "left",
      cellRendererParams: {
        suppressCount: true,
      },
      tooltipValueGetter: ClusterNameCellTooltipRenderer,
      cellRendererSelector: (params) => {
        return {
          component: ClusterNameRenderer,
        };
      },
      suppressHeaderFilterButton: true,
      sortable: false,
      menuTabs: [],
      suppressMenu: true,
      minWidth: 340,
      width: 340,
    };
  }, []);


  const onCellDoubleClicked = useCallback((params) => {
    if (params.colDef.showRowGroup) {
      params.node.setExpanded(!params.node.expanded);
    }
  }, []);

  useEffect(() => {
    getServerSideDatasourceClusterPPC(
        getData,
        setColumnDefs,
        search,
        customColumns,
        campaignId
    ).then((rows) => {
      if (rows?.length > 0) {
        setAnyClusterAuctions(true);
        setNumberOfClusterAuctions(rows.length);
      }

      const activeAuctions = rows.filter(row => row.status.indexOf("Bid Active") !== -1);

      setClusterActiveAuctions(activeAuctions);

      const inheritedAuctionsAndNotShown = rows.filter(row => {
          const inheritedStatus = row.status.indexOf("Inherited") !== -1;
          const position = ClusterPositionCellTooltipRenderer({value: row.position});

          let isNotShown = false;

          if (position.indexOf("not shown") !== -1) {
            isNotShown = true;
          }

          return inheritedStatus && isNotShown;
      });

      setClusterInheritedAuctionsAndNotShown(inheritedAuctionsAndNotShown);

      setData(rows);
    }).catch((error) => {
        console.error(error);
    });
  }, [search, campaignId, updatePage]);

  const onCellKeyDown = useCallback((params) => {
    if (!("colDef" in params)) {
      return;
    }
    if (!(params.event instanceof KeyboardEvent)) {
      return;
    }
    if (params.event.code !== "Enter") {
      return;
    }
    if (params.colDef.showRowGroup) {
      params.node.setExpanded(!params.node.expanded);
    }
  }, []);

  const onFilterTextBoxChanged = (e) => {
    setSearch(e.target.value);
  };

  if (display !== "cluster" || data?.length === 0) {
    return <></>;
  }

  return (
    <Card className="card-one">
      <Card.Header className="d-flex w-100">
        <Card.Title as="h6" className="mb-2 w-100">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <span className="me-2">Topic Cluster Auctions</span>
            </div>
          </div>
        </Card.Title>
      </Card.Header>
     
      <Card.Body>
        <div style={containerStyle}>
          <div
            style={gridStyle}
            className={
              skin === "dark" ? "ag-theme-quartz-dark ag-theme-quartz" : "ag-theme-quartz-light ag-theme-quartz"
            }
          >
            {/* <div style={{ marginBottom: "10px" }}>
              <input
                type="text"
                className="input-search"
                placeholder="Search..."
                onInput={onFilterTextBoxChanged}
              />
            </div> */}
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              rowData={data}
              rowHeight={65}
              suppressContextMenu={true}
              onCellClicked={onClick}
              loading={data.length === 0}
              treeData={true}
              groupDefaultExpanded={-1}
              getDataPath={getDataPath}
              onCellDoubleClicked={onCellDoubleClicked}
              onCellKeyDown={onCellKeyDown}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
