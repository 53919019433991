export const getUserName = user => {
    const userFirstName = user.first_name
    const userLastName = user.last_name

    let userName = ''

    if (userFirstName) {
        userName = userFirstName
    }

    if (userLastName) {
        userName = `${userName} ${userLastName}`
    }

    if (userName.trim().length === 0) {
        return user.email ? user.email.split('@')[0] : 'User loading..'
    } else {
        return userName.trim()
    }
}
