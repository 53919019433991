import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";
import { endOfDay, startOfDay, subDays } from "date-fns";

async function getPeriodClusterPPCAuctions({ search = '', page = 1 } = {}, campaignId, groupKeys = []) {
    try {
        const currentPeriod = [
            startOfDay(subDays(new Date(), 30)), endOfDay(new Date())
        ];
        
        const previousPeriod = [
            startOfDay(subDays(new Date(), 60)), endOfDay(subDays(new Date(), 30))
        ];

        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/auctions/cluster-table?page=' + page + '&search=' + search, {
            current_period: [normalizeToUTC(currentPeriod[0]).getTime(), normalizeToUTC(currentPeriod[1]).getTime()],
            previous_period: [normalizeToUTC(previousPeriod[0]).getTime(), normalizeToUTC(previousPeriod[1]).getTime()],
            campaign_id: Number(campaignId),
            group_keys: groupKeys
        })
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodClusterPPCAuctions;
