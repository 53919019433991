import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

export default async function getMe(oldToken, advertiserId) {
  const token = oldToken || getToken(advertiserId);

  if (!token) {
    console.error("Authorization token is missing.");
    return null;
  }

  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_HUB_URL}/api/users/me`,
      {
        headers: {
          authorization: token,
        },
      }
    );

    return response?.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
}
