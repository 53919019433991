import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function createPaymentIntent(values) {
  try {
    const token = getToken();
    axiosInstance.defaults.headers.common["authorization"] = token;
    const response = await axiosInstance.post(
      process.env.REACT_APP_HUB_URL + "/api/stripe/create-payment-intent",
      values
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
}

export default createPaymentIntent;
