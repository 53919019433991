import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodCampaignInventoryStats(currentPeriod, previousPeriod, campaignId, current_token, view, page, search) {    
    try {
        const token = current_token || getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/campaign/period/inventory-stats', {
            current_period: [normalizeToUTC(currentPeriod[0]).getTime(), normalizeToUTC(currentPeriod[1]).getTime()],
            previous_period: [normalizeToUTC(previousPeriod[0]).getTime(), normalizeToUTC(previousPeriod[1]).getTime()],
            campaign_id: campaignId,
            view: view
        })

        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodCampaignInventoryStats;
