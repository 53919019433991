import { logosMap } from '../maps'
import defaultLogo from './default'
import lightLogo from '../../assets/svg/revbox-logo.svg'
import darkLogo from '../../assets/svg/revbox-logo-white.svg'

export function logosModule(publisher_id, theme, custom_style = {}) {
    try {
        const isPublisherIdPresent = Object.keys(logosMap).includes(String(publisher_id))

        if (isPublisherIdPresent) {
            return logosMap[publisher_id](custom_style || {})
        } else {
            return theme === 'dark' ? (
                Object.keys(custom_style || {}).length > 0 ? (
                    <img src={darkLogo} alt="Dark Logo" style={custom_style || {}} />
                ) : (
                    <img src={darkLogo} className="logoDarkClass" alt="Dark Logo" />
                )
            ) : Object.keys(custom_style || {}).length > 0 ? (
                <img src={lightLogo} alt="Light Logo" style={custom_style || {}} />
            ) : (
                <img src={lightLogo} className="logoLightClass" alt="Light Logo" />
            )
        }
    } catch (error) {
        console.error('Error loading logo module:', error)
        return defaultLogo(theme, custom_style)
    }
}
