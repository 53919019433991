import React, { useEffect, useState, memo } from "react";
import { getPeriodTopCampaigns } from "../../../services/micro";
import ReactApexChart from "react-apexcharts";
import { Card, Placeholder, Nav } from "react-bootstrap";
import { formatThousand } from "../../../helpers/thousand-number-format";

function getRandomBG() {
  const colors = ["orange", "info", "success", "primary", "ui-02"];
  return colors[Math.floor(Math.random() * colors.length)];
}

const optionDonut = {
  chart: {
    parentHeightOffset: 0,
  },
  colors: ["#dc3545", "#ffc107", "#0cb785", "#f8f9fc", "#dc3545"],
  dataLabels: { enabled: true },
  grid: {
    padding: {
      top: 0,
      bottom: 0,
    },
  },
  legend: { show: false },
  stroke: {
    width: 0,
  },
  tooltip: {
    enabled: true,
    fillSeriesColor: false,
    theme: "dark",
    onDatasetHover: {
      highlightDataSeries: false,
    },
    x: {
      show: true,
    },
    y: {
      formatter: function (val) {
        return val;
      },
      title: {
        formatter: function (seriesName) {
          return " : ";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

function TopRevenue(props) {
  const { currentPeriod, previousPeriod } = props;
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (currentPeriod[0] && currentPeriod[1] && previousPeriod[0] && previousPeriod[1]) {
        getPeriodTopCampaigns(currentPeriod, previousPeriod).then((res) => {
          if (!res?.data) {
            setResponse(null);
            return;
          }
          const value_of_three = res.data.top_campaigns
            .slice(0, 3)
            .map((item) => {
              return Number(item.amount);
            })
            .reduce((a, b) => a + b, 0);

          const rest_value = Number(res.data.total) - value_of_three;
          const campaigns = res.data.top_campaigns.slice(0, 3).map((item) => {
            return {
              name: item.name,
              value: Number(item.amount),
              percent: Number(item.difference),
              bg: getRandomBG(),
            };
          });

          campaigns.push({
            name: "Others",
            value: rest_value > 0 ? rest_value : 0,
            percent: Number(
              Number(
                100 - (value_of_three / Number(res.data.total)) * 100
              ).toFixed(0)
            ),
            bg: "light",
          });

          const all_campaigns = [...campaigns];
          const first_three = [...campaigns].slice(0, 3);

          const total_percent = first_three
            .map((item) => {
              return Number(
                Number(
                  (Number(item.value) / Number(res.data.total)) * 100
                ).toFixed(1)
              );
            })
            .reduce((a, b) => a + b, 0);

          const total_revenue_for_current_period = res.data.top_campaigns
            .slice(0, 3)
            .reduce((a, b) => {
              return a + Number(b.amount);
            }, 0);

          setResponse({
            total: first_three
              .map((item) => {
                return Number(item.value);
              })
              .reduce((a, b) => a + b, 0),
            total_revenue_for_current_period: formatThousand(
              total_revenue_for_current_period
            ),
            percents: all_campaigns.map((item) => {
              return Number(
                Number(
                  (Number(item.value) / Number(res.data.total)) * 100
                ).toFixed(1)
              );
            }),
            total_percent: total_percent ? total_percent.toFixed(1) : 0,
          });
        });
      }
    }
  }, [props]);

  if (response) {
    return (
      <React.Fragment>
        <Card className="card-one">
          <Card.Header>
            <Card.Title as="h6">Top 3 Customer Revenue</Card.Title>
            <Nav className="nav-icon nav-icon-sm ms-auto"></Nav>
          </Card.Header>
          <Card.Body className="position-relative d-flex justify-content-center">
            <ReactApexChart
              series={response.percents}
              options={optionDonut}
              height="auto"
              type="donut"
              className="apex-donut-two"
            />

            <div className="finance-donut-value">
              <h2 className="card-value mb-0" style={{ fontSize: "1.2em" }}>
                ${response.total_revenue_for_current_period}
              </h2>
              <p>{response.total_percent}%</p>
            </div>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" className="h-100" style={{color: "var(--bs-body-color)"}}>
          <Placeholder className="w-100 h-100" />
        </Placeholder>
      </React.Fragment>
    );
  }
}

export default memo(TopRevenue);
