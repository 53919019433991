import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function getMyUsers() {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.get(process.env.REACT_APP_HUB_URL + '/api/users/my')
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getMyUsers;
