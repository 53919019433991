import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";

export default function AdvertiserNewCampaign() {

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="/adv/home" to={"/adv/home"}>
                  ADV Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                New Campaign
              </li>
            </ol>
            <h2 className="main-title mb-0">New Campaign</h2>
          </div>
        </div>

        <Row className="g-3 justify-content-center mt-3">
          <Col md="12">
            <Card className="card-settings">
              <Card.Header>
                <Card.Title>Product Information</Card.Title>
                <Card.Text className="text-warning">
                  <i className="ri-error-warning-line"></i> Please just create one
                  campaign per product you offer.
                </Card.Text>
                <Card.Text className="text-warning">
                  This information is used globally for your campaign.
                </Card.Text>
              </Card.Header>
              <Card.Body className="p-0">
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Product Name</h6>
                      <p>Required</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="Enter name" />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2">
                    <Col md="2">
                      <h6>Main Landing Page</h6>
                      <p>Required</p>
                    </Col>
                    <Col md>
                      <Form.Control type="text" placeholder="http(s)://" />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Product Logo</h6>
                      <p>
                        Required
                        <br />
                        150x150px, JPG/PNG
                      </p>
                    </Col>
                    <Col md>
                      <Button variant="" className="btn-white">
                        Upload Logo
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Screenshots</h6>
                      <p>
                        1 min, add up to 4<br />
                        1600x900px, JPG/PNG
                      </p>
                    </Col>
                    <Col md>
                      <Button variant="" className="btn-white">
                        Upload Screenshots
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2">
                      <h6>Billing</h6>
                      <p>Required</p>
                    </Col>
                    <Col md>
                      <Form.Check
                        type="checkbox"
                        checked
                        label="Monthly Net 30 - you will be billed at the end of each billing period and are required to pay via bank transfer within 30 days."
                        className="mt-3"
                      />
                      <Form.Check
                        type="checkbox"
                        label="Credit Card [option coming soon]"
                        disabled="disabled"
                        className="mt-1"
                      />
                    </Col>
                  </Row>
                </div>
                <div className="setting-item">
                  <Row className="g-2 align-items-center">
                    <Col md="2"></Col>
                    <Col md>
                      <Button variant="primary">Create Campaign</Button>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        
      </div>
    </React.Fragment>
  );
}
