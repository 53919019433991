import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getAllCampaings(advertiserId) {
  try {
    const token = getToken(advertiserId);
    axiosInstance.defaults.headers.common["authorization"] = token;

    const response = await axiosInstance.get(
      `/api/client-campaigns/all/adv?advertiserId=${advertiserId}`
    );

    return response?.data;
  } catch (error) {
    console.log(error);
    throw error; // Re-throw the error for further handling if needed
  }
}

export default getAllCampaings;
