import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function deleteInventoryCampaign(id) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.delete(process.env.REACT_APP_HUB_URL + '/api/inventory-campaigns/' + id)
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default deleteInventoryCampaign;
