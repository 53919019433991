import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodAdvertiserPerformance(currentPeriod, previousPeriod, status, search, page, inventoryId, sortingParams) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const url = new URL(`${process.env.REACT_APP_HUB_URL}/api/analytics/advertiser/period/performance`);

        const queryParams = {
            status,
            page,
            search,
            column_name: sortingParams?.columnName,
            column_order: sortingParams?.sortOrder
        };

        Object.entries(queryParams).forEach(([key, value]) => {
            if (value) url.searchParams.set(key, value);
        });

        const response = await axiosInstance.post(url.toString(), {
            current_period: [normalizeToUTC(currentPeriod[0]).getTime(), normalizeToUTC(currentPeriod[1]).getTime()],
            previous_period: [normalizeToUTC(previousPeriod[0]).getTime(), normalizeToUTC(previousPeriod[1]).getTime()],
        });

        return response?.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}

export default getPeriodAdvertiserPerformance;
