import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function changePassword(user_id) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        let response
        if (user_id) {
            response = await axiosInstance.put(process.env.REACT_APP_HUB_URL + '/api/users/change-password?user_id=' + user_id)
        } else {
            response = await axiosInstance.put(process.env.REACT_APP_HUB_URL + '/api/users/change-password')
        }
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default changePassword;
