import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-country-state-city/dist/react-country-state-city.css";
import { toast } from "react-toastify";
import { editCampaign, getAllBillingMethodsSelector } from "../../services/adv";

import CreditCardPostPaymentIcon from "./images/credit-card-post-payment.svg";
import CreditCardTopUpIcon from "./images/credit-card-top-up.svg";
import InvoicePostPaymentIcon from "./images/invoice-post-payment.svg";
import InvoiceTopUpIcon from "./images/invoice-top-up.svg";
import { getPublisher } from "../../services/pub";

const InvoiceTopUp = 1;
const InvoicePostPayment = 2;
const CreditCardTopUp = 3;
const CreditCardPostPayment = 4;

export default function CampaignActivation() {
    const campaignId = new URLSearchParams(window.location.search).get('campaignId');
    const paramBudget = new URLSearchParams(window.location.search).get('budget') || 500; // TODO: add min budget
    const urlContactName = new URLSearchParams(window.location.search).get('contactName');
    const user = useSelector((state) => state.user);
    const [budget, setBudget] = useState(Number(paramBudget));
    const [billingMethods, setBillingMethods] = useState([]);
    const [billingMethod, setBillingMethod] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [topUpAmount, setTopUpAmount] = useState(0);


    const [initialTopUpAmount, setInitialTopUpAmount] = useState(0);
    const [customInitialTopUpAmountIsSelected, setCustomInitialTopUpAmountIsSelected] = useState(false);
    const [autoTopUpThreshold, setAutoTopUpThreshold] = useState(0);
    const [customAutoTopUpThresholdIsSelected, setCustomAutoTopUpThresholdIsSelected] = useState(false);
    const [autoTopUpAmount, setAutoTopUpAmount] = useState(0);
    const [customAutoTopUpAmountIsSelected, setCustomAutoTopUpAmountIsSelected] = useState(false);

    const changeInitialTopUpAmount = (amount) => {
        setInitialTopUpAmount(amount);
    }

    const changeAutoTopUpThreshold = (amount) => {
        setAutoTopUpThreshold(amount);
    }

    const changeAutoTopUpAmount = (amount) => {
        setAutoTopUpAmount(amount);
    }

    const handleInitialTopUpAmount = (e) => {
        setInitialTopUpAmount(Number(e.target.value));
        setCustomInitialTopUpAmountIsSelected(false);
    }

    const handleCustomInitialTopUpAmount = (e) => {
        setInitialTopUpAmount(0);
        setCustomInitialTopUpAmountIsSelected(!customInitialTopUpAmountIsSelected);
    }

    const handleAutoTopUpThreshold = (e) => {
        setAutoTopUpThreshold(Number(e.target.value));
        setCustomAutoTopUpThresholdIsSelected(false);
    }

    const handleCustomAutoTopUpThreshold = (e) => {
        setAutoTopUpThreshold(0);
        setCustomAutoTopUpThresholdIsSelected(!customAutoTopUpThresholdIsSelected);
    }

    const handleAutoTopUpAmount = (e) => {
        setAutoTopUpAmount(Number(e.target.value));
        setCustomAutoTopUpAmountIsSelected(false);
    }

    const handleCustomAutoTopUpAmount = (e) => {
        setAutoTopUpAmount(0);
        setCustomAutoTopUpAmountIsSelected(!customAutoTopUpAmountIsSelected);
    }

    const [publisher, setPublisher] = useState(null);
   

    const selectPaymentMethod = (method) => {
        setPaymentMethod(method);
        openBillingForm(method);
    }

    const openBillingForm = (method) => {
        window.location.href = `/adv/billing/new?campaignId=${campaignId}&billingMethod=${method}&budget=${budget}`;
    }

    // payment_intent=pi_3PtNnt2QWUHlIsKh14FCWVhF&payment_intent_client_secret=pi_3PtNnt2QWUHlIsKh14FCWVhF_secret_amIigCks470E8ipJH3Q4M5SNk&redirect_status=succeeded

    useEffect(() => {
        const fetchBillingMethods = async () => {
            try {
                const billingMethods = await getAllBillingMethodsSelector(campaignId);
                setBillingMethods(billingMethods.data);

                if (urlContactName) {
                    const selectedBillingMethod = billingMethods.data.find((method) => method.name === urlContactName);
                   
                    if (selectedBillingMethod) {
                        setBillingMethod(selectedBillingMethod.id);
                    }
                }

                const publisher_data = await getPublisher(user.publisher_id);
                setPublisher(publisher_data.data);
            } catch (error) {
                console.error("Error fetching billing methods:", error);
            }
        }

        fetchBillingMethods();
    }, []);

    const checkFormValues = (obj) => {
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'object' && value !== null) {
                if (key !== 'billingAddress') {
                    checkFormValues(value);
                } else {
                    for (const [nestedKey, nestedValue] of Object.entries(value)) {
                        if (!nestedValue && nestedKey !== 'line2') {
                            return false
                        }
                    }
                }
            } else if (!value && key !== 'line2') {
                return false
            }
        }
        return true;
    };

    const listOfBillingMethods = billingMethods.filter((method) => publisher?.approved_billing_types?.includes(method.type))
    .map((method) => {
        if (method.type == CreditCardPostPayment) {
            return { value: method.id, label: "Credit Card Post Payment | " + method.name + " | " + method.last4 }
        }

        if (method.type == CreditCardTopUp) {
            return { value: method.id, label: "Credit Card Top Up | " + method.name + " | " + method.last4 }
        }

        if (method.type == InvoicePostPayment) {
            return { value: method.id, label: "Invoice Post Payment | " + method.name }
        }

        if (method.type == InvoiceTopUp) {
            return { value: method.id, label: "Invoice Top Up | " + method.name }
        }

        return { value: method.id, label: method.name }
    });

    // add default value to the select like "create a new billing method"
    listOfBillingMethods.unshift({ value: 0, label: "Create a new billing method" });

    const onChangeMonthlyBudget = (e) => {
        setTimeout(() => {
          if (e.target.value < publisher?.min_budget || isNaN(e.target.value)) {
            toast.warn("Monthly budget can't be lower than $" + publisher?.min_budget);
            const blockBudget = document.getElementById("blockBudget");
            blockBudget.value = publisher?.min_budget;
            setBudget(publisher?.min_budget);
            return;
          }
    
          const blockBudget = document.getElementById("blockBudget");
          blockBudget.value = e.target.value;
          setBudget(e.target.value);
          return;
        }, 2000);
    };

    const onChangeTopUpAmount = (e) => {
        // should not be less than publisher?.default_budget
        setTimeout(() => {
            if (e.target.value < publisher?.default_budget || isNaN(e.target.value)) {
                toast.warn("Top up amount can't be lower than $" + publisher?.default_budget);
                const topUpAmount = document.getElementById("topUpAmount");
                topUpAmount.value = publisher?.default_budget;
                setTopUpAmount(publisher?.default_budget);
                return;
            }

            const topUpAmount = document.getElementById("topUpAmount");
            topUpAmount.value = e.target.value;
            setTopUpAmount(e.target.value);
            return;
        }, 2000);
    };

    const activateCampaign = async () => {
        try {
            await editCampaign(campaignId, { 
                ppc_monthly_budget: budget,
                billing_method_id: billingMethod,
                top_up_amount: topUpAmount,
                ppc_active: true
            });
            toast.success("Campaign activated successfully");
            window.location.href = `/adv/campaign/ppc?campaignId=${campaignId}`;
        } catch (error) {
            console.error("Error activating campaign:", error);
            toast.error("Error activating campaign");
        }
    }

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="/adv/home">ADV Home</Link></li>
                            <li className="breadcrumb-item" aria-current="page"><Link to={`/adv/campaign/ppc?campaignId=${campaignId}`}>PPC Auctions</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Campaign Activation</li>
                        </ol>
                        <h2 className="main-title mb-0">
                            Campaign Activation
                        </h2>
                    </div>
                </div>
            
                <Row className="g-3 justify-content-center">
                    <Col md="12">
                        <Row>
                            <Col sm="12" md="8">
                                <Card className="card-settings mt-2">
                                    <Card.Header className="card-header">
                                        <h5 className="card-title mb-0">Campaign Configuration</h5>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <div className="setting-item">
                                            <Row className="g-2 align-items-center">
                                                <Col md="3">
                                                    <h6>Budget</h6>
                                                    <p>Required</p>
                                                </Col>
                                                <Col md>
                                                    <div className="d-flex align-items-center">
                                                        <span className="d-block text-muted" style={{ marginLeft: -15, marginRight: 5 }}>$</span>
                                                        <Form.Control
                                                            type="number"
                                                            id="blockBudget"
                                                            placeholder="Enter Budget"
                                                            onChange={(e) => onChangeMonthlyBudget(e)}
                                                            defaultValue={publisher?.default_budget}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="setting-item">
                                            <Row className="g-2 align-items-center">
                                                <Col md="3">
                                                    <h6>Billing Method</h6>
                                                    <p>Required</p>
                                                </Col>
                                                <Col md>
                                                    <Select 
                                                        id="basic-select-single" 
                                                        onChange={(e) => {
                                                            setBillingMethod(e.value); 
                                                        }}
                                                        value={listOfBillingMethods.find((method) => method.value === billingMethod)}
                                                        options={listOfBillingMethods}
                                                        defaultValue={listOfBillingMethods[0]}
                                                        placeholder={"Select Billing Method"}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                            {/* {
                                                listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Top') &&
                                                <Row className="g-2 align-items-center mt-4">
                                                    <Col md="3">
                                                        <h6>Top Up Amount</h6>
                                                        <p>Required</p>
                                                    </Col>
                                                    <Col md>
                                                        <div className="d-flex align-items-center">
                                                            <span className="d-block text-muted" style={{ marginLeft: -15, marginRight: 5 }}>$</span>
                                                            <Form.Control
                                                                type="number"
                                                                id="topUpAmount"
                                                                placeholder="Enter Top Up Amount"
                                                                onChange={(e) => onChangeTopUpAmount(e)}
                                                                defaultValue={0}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            } */}
                                            
                                            { billingMethod === 0 &&
                                                <div className="setting-item">
                                                    <Row className="g-2 align-items-center">
                                                        {[
                                                            {
                                                                "img": InvoiceTopUpIcon,
                                                                "billingMethod": 1,
                                                                "title": "Bank Transfer Top Up",
                                                                "text": "Bank Transfer Top Up is a method of adding funds to your account by issuing an invoice"
                                                            }, {
                                                                "img": InvoicePostPaymentIcon,
                                                                "billingMethod": 2,
                                                                "title": "Bank Transfer Post Payment",
                                                                "text": "Bank Transfer Post Payment is a method of paying for your campaign after it has ended"
                                                            }, {
                                                                "img": CreditCardTopUpIcon,
                                                                "billingMethod": 3,
                                                                "title": "Credit Card Top Up",
                                                                "text": "Credit Card Top Up is a method of adding funds to your account using a credit card"
                                                            }, {
                                                                "img": CreditCardPostPaymentIcon,
                                                                "billingMethod": 4,
                                                                "title": "Credit Card Post Payment",
                                                                "text": "Credit Card Post Payment is a method of paying for your campaign after it has ended"
                                                            }
                                                            ].filter((item) => publisher?.approved_billing_types?.includes(item.billingMethod))
                                                            .map((item, index) => (
                                                                <div 
                                                                    className="news-item d-flex align-items-center p-3 border rounded-2 campaign-configuration-item"
                                                                    key={index}
                                                                    onClick={() => selectPaymentMethod(item.billingMethod)}
                                                                >
                                                                    <div className="news-img text-center" style={{ width: '90px' }}>
                                                                        <img src={item.img} className="img-fluid" alt="" style={{ width: '90px' }}/>
                                                                    </div>
                                                                    <div className="news-body">
                                                                        <h6 className="news-title fw-semibold">
                                                                            <div className="text-dark">{item.title}</div>
                                                                        </h6>
                                                                        <p className="news-text mb-0">{item.text}</p>
                                                                    </div>
                                                                    <div className="news-meta ms-auto" style={{ fontSize: '1.5rem' }}>
                                                                        <i className="ri-arrow-right-s-line"></i>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </Row>
                                                </div>
                                            }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    {
                        listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Card Top') &&
                            <Col md="12">
                                <Row>
                                    <Col sm="12" md="8">
                                        <Card className="card-settings mt-2">
                                            <Card.Header className="card-header">
                                                <h5 className="card-title mb-0">Top Up Configuration</h5>
                                            </Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="setting-item">
                                                    <Row className="g-2">
                                                        <Col md="3">
                                                            <h6>Initial Top-Up Amount</h6>
                                                            <p>This top-up payment will be processed upon activation. Once it clears, your campaign will start automatically.</p>
                                                        </Col>
                                                        <Col md>
                                                            <div className="d-flex">
                                                                <Form.Check type="radio" label="$250" style={{ marginRight: 25 }} value={250} checked={initialTopUpAmount === 250} onChange={handleInitialTopUpAmount} />
                                                                <Form.Check type="radio" label="$500" style={{ marginRight: 25 }} value={500} checked={initialTopUpAmount === 500} onChange={handleInitialTopUpAmount} />
                                                                <Form.Check type="radio" label="$1000" style={{ marginRight: 25 }} value={1000} checked={initialTopUpAmount === 1000} onChange={handleInitialTopUpAmount} />
                                                                <Form.Check type="radio" label="$2000" style={{ marginRight: 25 }} value={2000} checked={initialTopUpAmount === 2000} onChange={handleInitialTopUpAmount} />
                                                                <Form.Check type="radio" label="Custom" checked={customInitialTopUpAmountIsSelected} onChange={handleCustomInitialTopUpAmount} />
                                                            </div>
                                                            {
                                                                customInitialTopUpAmountIsSelected &&
                                                                <Form.Control
                                                                    className="mt-2"
                                                                    type="number"
                                                                    placeholder="Enter Initial Top Up Amount"
                                                                    onChange={(e) => changeInitialTopUpAmount(e.target.value)}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="setting-item">
                                                    <Row className="g-2">
                                                        <Col md="3">
                                                            <h6>Auto Top-Up Threshold</h6>
                                                            <p>When your balance drops below this threshold, the Auto Top-Up Amount will be billed to your account.</p>
                                                        </Col>
                                                        <Col md>
                                                            <div className="d-flex">
                                                                <Form.Check type="radio" label="$150" style={{ marginRight: 25 }} value={150} checked={autoTopUpThreshold === 150} onChange={handleAutoTopUpThreshold} />
                                                                <Form.Check type="radio" label="Custom" checked={customAutoTopUpThresholdIsSelected} onChange={handleCustomAutoTopUpThreshold} />
                                                            </div>
                                                            {
                                                                customAutoTopUpThresholdIsSelected &&
                                                                <Form.Control
                                                                    className="mt-2"
                                                                    type="number"
                                                                    placeholder="Enter Auto Top Up Threshold"
                                                                    onChange={(e) => changeAutoTopUpThreshold(e.target.value)}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="setting-item">
                                                    <Row className="g-2">
                                                        <Col md="3">
                                                            <h6>Auto Top-Up Amount</h6>
                                                            <p>When your credit balance falls below the Auto Top-Up Threshold, this top-up amount will be billed to your account.</p>
                                                        </Col>
                                                        <Col md>
                                                            <div className="d-flex">
                                                                <Form.Check type="radio" label="$250" style={{ marginRight: 25 }} value={250} checked={autoTopUpAmount === 250} onChange={handleAutoTopUpAmount} />
                                                                <Form.Check type="radio" label="$500" style={{ marginRight: 25 }} value={500} checked={autoTopUpAmount === 500} onChange={handleAutoTopUpAmount} />
                                                                <Form.Check type="radio" label="$1000" style={{ marginRight: 25 }} value={1000} checked={autoTopUpAmount === 1000} onChange={handleAutoTopUpAmount} />
                                                                <Form.Check type="radio" label="$2000" style={{ marginRight: 25 }} value={2000} checked={autoTopUpAmount === 2000} onChange={handleAutoTopUpAmount} />
                                                                <Form.Check type="radio" label="Custom" checked={customAutoTopUpAmountIsSelected} onChange={handleCustomAutoTopUpAmount} />
                                                            </div>
                                                            {
                                                                customAutoTopUpAmountIsSelected &&
                                                                <Form.Control
                                                                    className="mt-2"
                                                                    type="number"
                                                                    placeholder="Enter Auto Top Up Amount"
                                                                    onChange={(e) => changeAutoTopUpAmount(e.target.value)}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                    }
                    {
                        listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Invoice Top') &&
                            <Col md="12">
                                <Row>
                                    <Col sm="12" md="8">
                                        <Card className="card-settings mt-2">
                                            <Card.Header className="card-header">
                                                <h5 className="card-title mb-0">Top Up Configuration</h5>
                                            </Card.Header>
                                            <Card.Body className="p-0">
                                                <div className="setting-item">
                                                    <Row className="g-2">
                                                        <Col md="3">
                                                            <h6>Initial Top-Up Amount</h6>
                                                            <p>This top-up payment will be processed upon activation. Once it clears, your campaign will start automatically.</p>
                                                        </Col>
                                                        <Col md>
                                                            <div className="d-flex">
                                                                <Form.Check type="radio" label="$5000" style={{ marginRight: 25 }} value={5000} checked={initialTopUpAmount === 5000} onChange={handleInitialTopUpAmount} />
                                                                <Form.Check type="radio" label="$20000" style={{ marginRight: 25 }} value={20000} checked={initialTopUpAmount === 20000} onChange={handleInitialTopUpAmount} />
                                                                <Form.Check type="radio" label="$50000" style={{ marginRight: 25 }} value={50000} checked={initialTopUpAmount === 50000} onChange={handleInitialTopUpAmount} />
                                                                <Form.Check type="radio" label="Custom" checked={customInitialTopUpAmountIsSelected} onChange={handleCustomInitialTopUpAmount} />
                                                            </div>
                                                            {
                                                                customInitialTopUpAmountIsSelected &&
                                                                <Form.Control
                                                                    className="mt-2"
                                                                    type="number"
                                                                    placeholder="Enter Initial Top Up Amount"
                                                                    onChange={(e) => changeInitialTopUpAmount(e.target.value)}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="setting-item">
                                                    <Row className="g-2">
                                                        <Col md="3">
                                                            <h6>Auto Top-Up Threshold</h6>
                                                            <p>When your balance drops below this threshold, the Auto Top-Up Amount will be billed to your account.</p>
                                                        </Col>
                                                        <Col md>
                                                            <div className="d-flex">
                                                                <Form.Check type="radio" label="$1000" style={{ marginRight: 25 }} value={1000} checked={autoTopUpThreshold === 1000} onChange={handleAutoTopUpThreshold} />
                                                                <Form.Check type="radio" label="Custom" checked={customAutoTopUpThresholdIsSelected} onChange={handleCustomAutoTopUpThreshold} />
                                                            </div>
                                                            {
                                                                customAutoTopUpThresholdIsSelected &&
                                                                <Form.Control
                                                                    className="mt-2"
                                                                    type="number"
                                                                    placeholder="Enter Auto Top Up Threshold"
                                                                    onChange={(e) => changeAutoTopUpThreshold(e.target.value)}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="setting-item">
                                                    <Row className="g-2">
                                                        <Col md="3">
                                                            <h6>Auto Top-Up Amount</h6>
                                                            <p>When your credit balance falls below the Auto Top-Up Threshold, this top-up amount will be billed to your account.</p>
                                                        </Col>
                                                        <Col md>
                                                            <div className="d-flex">
                                                                <Form.Check type="radio" label="$5000" style={{ marginRight: 25 }} value={5000} checked={autoTopUpAmount === 5000} onChange={handleAutoTopUpAmount} />
                                                                <Form.Check type="radio" label="$20000" style={{ marginRight: 25 }} value={20000} checked={autoTopUpAmount === 20000} onChange={handleAutoTopUpAmount} />
                                                                <Form.Check type="radio" label="$50000" style={{ marginRight: 25 }} value={50000} checked={autoTopUpAmount === 50000} onChange={handleAutoTopUpAmount} />
                                                                <Form.Check type="radio" label="Custom" checked={customAutoTopUpAmountIsSelected} onChange={handleCustomAutoTopUpAmount} />
                                                            </div>
                                                            {
                                                                customAutoTopUpAmountIsSelected &&
                                                                <Form.Control
                                                                    className="mt-2"
                                                                    type="number"
                                                                    placeholder="Enter Auto Top Up Amount"
                                                                    onChange={(e) => changeAutoTopUpAmount(e.target.value)}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                    }
                </Row>
                <Col sm="12" md="8">
                    <Row className="g-2 mt-4 d-flex flex-wrap">
                        {
                            listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Card Top') &&
                            <Col md="8" className="text-muted mb-2">Upon activation, your credit card will be charged for the <b>Initial Top-Up Amount</b>, and the credits will appear in your account. Whenever your credit balance falls below the <b>Auto Top-Up Threshold</b>, your credit card will be charged for the <b>Auto Top-Up Amount</b>. The campaign will pause when your monthly budget has been reached or when your credit balance reaches zero.</Col>
                        }
                        {
                            listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Invoice Top') &&
                            <Col md="8" className="text-muted mb-2">Upon activation, you will receive an invoice for the <b>Initial Top-Up Amount</b>, which must be paid via bank transfer. Once the payment is reconciled, the credits will appear in your account and your campaign will start. Whenever your balance falls below the <b>Auto Top-Up Threshold</b>, you will receive a new invoice for your <b>Auto Top-Up Amount</b>. The campaign will pause when your monthly budget has been reached or when your credit balance reaches zero.</Col>
                        }
                        {
                            listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Card Post') &&
                            <Col md="9" className="text-muted mb-2">Your campaign will be activated immediately, and your campaign spend will be charged to your credit card at the end of each billing period. The campaign will pause automatically when the budget is reached and resume at the start of the next billing period.</Col>
                        }
                        {
                            listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Invoice Post') &&
                            <Col md="9" className="text-muted mb-2">Your campaign will be activated immediately, and your campaign spend will be invoiced at the end of each billing period. You will need to settle the invoice via bank transfer. The campaign will pause automatically when the budget is reached and resume at the start of the next billing period.</Col>
                        }
                        {
                            billingMethod !== 0 && billingMethod !== null &&
                            <Col className="d-flex justify-content-end">
                                <Button
                                    className="btn btn-primary"
                                    style={{ height: 40, marginRight: 8 }}
                                    onClick={() => activateCampaign()}
                                >
                                    {
                                        listOfBillingMethods.find((method) => method.value === billingMethod)?.label?.includes('Card Top') ? "Proceed to Top Up & Activate Campaign" : "Activate Campaign"
                                    }
                                </Button>
                            </Col>
                        }
                    </Row>
                </Col>
            </div>
        </React.Fragment>
    )
}