import React, { useState } from 'react'
import { getPublisherInventories } from '../../services/pub'
import BasicTable from '../../components/BasicTable'
import { Breadcrumbs } from './components/Breadcrumbs'
import { AddItemButton } from './components/AddItemButton'
import { Nav } from 'react-bootstrap'
import { InventoryTypeRenderer } from './components/InventoryTypeRenderer'
import { CookiesKeyNames, useCookiesContext } from '../../contexts/CookiesContext'

const colWidth = {
    name: 250,
    inventory_type: 160,
    website_id: 190,
    items_amount: 100,
    box_types: 180,
    related_categories_ids: 300,
    min_cpc: 120
}

const hiddenColumns = ['primary_category_id', 'primary_language_id']

const rowFields = ['id', 'name', 'inventory_type', 'website_id', 'items_amount', 'box_types', 'status', 'related_categories_ids', 'min_cpc']
const customColumns = [
    {
        field: 'inventory_type',
        cellRenderer: InventoryTypeRenderer
    }
]

export default function PublisherInventory() {
    const title = 'Inventories'
    const breadcrumb_redirect = '/pub/inventory/all'
    const breadcrumb = 'Inventory'
    const new_btn_title = 'New Inventory'
    const new_btn_redirect = '/pub/inventory/new'
    const edit_redirect = '/pub/inventory/edit'
    const { cookiesData, getPathKey } = useCookiesContext()

    const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === 'dark' ? 'dark' : ''
    const [invType, setInvType] = useState(null)

    const [countByInvType, setCountByInvType] = useState({
        departmentRecords: 0,
        specificRecords: 0,
        homepageRecords: 0,
        bannerRecords: 0,
        clusterRecords: 0,
        total: 0
    })

    return (
        <React.Fragment>
            <div className="main main-app d-flex flex-column p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
                    <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
                </div>

                <Nav className="nav-line mb-4 ml-4">
                    {
                        <Nav.Link href="#department" onClick={() => setInvType(1)}>
                            Department ({countByInvType.departmentRecords})
                        </Nav.Link>
                    }
                    {
                        <Nav.Link href="#specific" onClick={() => setInvType(2)}>
                            Specific ({countByInvType.specificRecords})
                        </Nav.Link>
                    }
                    {
                        <Nav.Link href="#homepage" onClick={() => setInvType(3)}>
                            Homepage ({countByInvType.homepageRecords})
                        </Nav.Link>
                    }
                    {
                        <Nav.Link href="#banner" onClick={() => setInvType(4)}>
                            Banner ({countByInvType.bannerRecords})
                        </Nav.Link>
                    }
                    {
                        <Nav.Link href="#cluster" onClick={() => setInvType(5)}>
                            Cluster ({countByInvType.clusterRecords})
                        </Nav.Link>
                    }
                    {
                        <Nav.Link href="#all" onClick={() => setInvType(null)} className={invType === null ? 'active' : ''}>
                            All ({countByInvType.total})
                        </Nav.Link>
                    }
                </Nav>

                <BasicTable
                    getData={getPublisherInventories}
                    url={edit_redirect}
                    hiddenColumns={hiddenColumns}
                    colWidth={colWidth}
                    rowFields={rowFields}
                    skin={currentSkin}
                    status={invType}
                    customColumns={customColumns}
                    setCountByStatus={setCountByInvType}
                />

                
            </div>
        </React.Fragment>
    )
}
