import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

import BasicTable from '../BasicTable'
import UsersSwitchTable from '../UsersSwitchTable'

import { getAllCustomers, getAllAdvUsers } from '../../services/adv'
import { getPublisher } from '../../services/pub'
import { generateUserTokenByAdmin } from '../../services/auth'
import { clearAdvertiser } from '../../features/advertiser'
import { setAdvertiser } from '../../features/advertiser'
import { encrypt } from '../../helpers/crypto'
import { CookiesKeyNames } from '../../contexts/CookiesContext'
import { useCookiesContext } from '../../contexts/CookiesContext'
const colWidth = {
    id: 80,
    account_name: 200,
    created_at: 200
}

const hiddenColumns = ['account_legal_name', 'account_registered_address_country_id', 'account_vat_number']

const rowFields = ['id', 'account_name', 'created_at']

function AccountsModal({ setModalShow, navigate, dispatch, modalShow, user }) {
    const {isDarkMode, updateCookiesProperty, getToken, getPublisherId} = useCookiesContext();
    const [advId, setAdvId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [advUsers, setAdvUsers] = useState([])

    const onCloseModal = () => {
        setAdvUsers([])
        setAdvId(null)
        setModalShow(false)
        setLoading(false)
    }

    const createNewUser = () => {
        navigate('/pub/customer/user/new')
        onCloseModal()
    }

    const modalHandleSwitch = ({ data }) => {
        setLoading(true)
        if (data?.id) {
            setAdvId(data.id)

            getAllAdvUsers(data.id)
                .then(res => {
                    setAdvUsers([user, ...res.data])
                })
                .catch(err => {
                    setAdvUsers([user])
                    console.log(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const handleUserSwitch = user_id => {
        const publisherId = getPublisherId()
        const userToSwitch = user_id === user.id ? user : advUsers.find(u => u.id === user_id)

        if (!userToSwitch) {
            throw new Error('User not found')
        }

        getPublisher(publisherId)
            .then(res => {
                const publisher = res.data
                const api_key = publisher.api_key
                const old_token = getToken()

                if (userToSwitch.role === 'ADVERTISER' || userToSwitch.role === 'CUSTOMER') {
                    generateUserTokenByAdmin(api_key, user_id)
                        .then(user_token => {
                            const token = user_token.data.token
                            // clearUser()
                            clearAdvertiser()
                            dispatch(setAdvertiser({ id: advId }))
                            updateCookiesProperty(CookiesKeyNames.sidebarSkin, 'advertiser', advId)
                            updateCookiesProperty(CookiesKeyNames.publisherId, publisher.id, advId)
                            updateCookiesProperty(CookiesKeyNames.token, token, advId)
                            updateCookiesProperty(CookiesKeyNames.advertiserId, advId, advId)
                            updateCookiesProperty(CookiesKeyNames.publisherToken, old_token, advId)
                            updateCookiesProperty(CookiesKeyNames.skinMode, isDarkMode() ? 'dark' : '', advId)
                            updateCookiesProperty(CookiesKeyNames.publisherId, publisher.id, advId)

                            // dispatch(setUser(newUser))
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }

                if (userToSwitch.role === 'PUBLISHER' || userToSwitch.role === 'ADMIN') {
                    clearAdvertiser()
                    dispatch(setAdvertiser({ id: advId }))
                    updateCookiesProperty(CookiesKeyNames.token, old_token, advId)
                    updateCookiesProperty(CookiesKeyNames.advertiserId, advId, advId)
                    updateCookiesProperty(CookiesKeyNames.sidebarSkin, 'publisher', advId)
                    updateCookiesProperty(CookiesKeyNames.publisherId, publisher.id, advId)
                    updateCookiesProperty(CookiesKeyNames.skinMode, isDarkMode() ? 'dark' : '', advId)
                }

                const encryptedAdvId = encrypt(advId)
                const navigateLink = '/adv/home?pub=' + publisher.abbreviation + '&advId=' + encryptedAdvId
                window.open(navigateLink, '_blank')
                setModalShow(false)

                // if (user_id === user.id && user.role === 'ADVERTISER') {
                //     clearAdvertiser()
                //     dispatch(setAdvertiser({ id: advId }))
                //     updateCookiesProperty(CookiesKeyNames.token, old_token)
                //     updateCookiesProperty(CookiesKeyNames.advertiserId, advId)
                //     updateCookiesProperty(CookiesKeyNames.skinMode, isDarkMode() ? 'dark' : '')
                //     updateCookiesProperty(CookiesKeyNames.sidebarSkin, 'publisher')
                //     updateCookiesProperty(CookiesKeyNames.publisherId, publisher.id)

                //     const navigateLink = '/adv/home?pub=' + publisher.abbreviation

                //     navigate(navigateLink)
                //     window.location.reload()
                // }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Modal show={modalShow} onHide={onCloseModal} size="xl" className="accounts-modal">
            <Modal.Header closeButton>
                <Modal.Title className="align-items-center d-flex">
                    <button
                        style={{ fontSize: '15px', cursor: 'pointer' }}
                        className="btn btn-outline-primary btn-sm me-2"
                        title="Back"
                        onClick={() => {
                            setAdvUsers([])
                            setAdvId(null)
                        }}
                        disabled={advUsers.length === 0 && advId === null}
                    >
                        <i className="ri-arrow-left-line" title="Back"></i>
                    </button>
                    {advUsers.length === 0 && advId === null && (
                        <div className="align-items-center">
                            <b>Step 1/2</b> - Select account to switch
                        </div>
                    )}
                    {advUsers.length > 0 && advId !== null && (
                        <div className="align-items-center">
                            <b>Step 2/2</b> - Select user to login
                        </div>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body closeButton>
                {advUsers.length === 0 && advId === null && (
                    <BasicTable
                        getData={getAllCustomers}
                        modalHandleSwitch={modalHandleSwitch}
                        hiddenColumns={hiddenColumns}
                        colWidth={colWidth}
                        rowFields={rowFields}
                        skin={isDarkMode() ? 'dark' : ''}
                    />
                )}
                {advUsers.length > 0 && advId !== null && (
                    <UsersSwitchTable users={advUsers} onClick={handleUserSwitch} skin={isDarkMode() ? 'dark' : ''} />
                )}
                {advUsers.length === 0 && advId !== null && !loading && (
                    <div className="accounts-list p-3">
                        <h4 className="text-center">No users found, please create new user for this account</h4>
                        <div className="text-center mt-3">
                            <Button variant="primary" size="sm" onClick={() => createNewUser()}>
                                Create New User
                            </Button>
                        </div>
                    </div>
                )}
                {loading && (
                    <div className="text-center p-3">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default AccountsModal
