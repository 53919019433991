import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";

async function getPeriodInventoryInsightsTotals(currentPeriod, previousPeriod, inventoryId) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/inventory/insights/period/totals', {
            current_period: [normalizeToUTC(currentPeriod[0]).getTime(), normalizeToUTC(currentPeriod[1]).getTime()],
            previous_period: [normalizeToUTC(previousPeriod[0]).getTime(), normalizeToUTC(previousPeriod[1]).getTime()],
            inventory_id: inventoryId
        })
        return response?.data?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodInventoryInsightsTotals;
