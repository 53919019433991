import { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";
import { getUserRole } from "../helpers/user-role";
import { Button, Card, Col, Form, Nav, Row } from "react-bootstrap";
import { getAllLanguages, getAllRoles } from "../services/micro";
import { PromptDescription } from "../components/PromptDescription";
import { editPublisher, editPublisherWebhook, getPublisher, getWebhook } from "../services/pub";
import { toast } from "react-toastify";
import Select from "react-select";

export const Automations = () => {
    const user = useSelector((state) => state.user);
    const [userAccesses, setUserAccesses] = useState([]);
    const [roles, setRoles] = useState([]);
    const [publisher, setPublisher] = useState();
    const [webhook, setWebhook] = useState();
    const [languages, setLanguages] = useState([]);
    const [gptSpecificTaglinePrompt, setGptSpecificTaglinePrompt] = useState();
    const [gptClusterTaglinePrompt, setGptClusterTaglinePrompt] = useState();
    const [gptApplicationVerificationPrompt, setGptApplicationVerificationPrompt] = useState();
    const [gptDepartmentTaglinePrompt, setGptDepartmentTaglinePrompt] = useState();
    const [gptHomepageTaglinePrompt, setGptHomepageTaglinePrompt] = useState();
    const [gptDescriptionPrompt, setGptDescriptionPrompt] = useState();
    const [systemLanguages, setSystemLanguages] = useState();
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const copyApiKey = () => {
        navigator.clipboard.writeText(publisher.api_key);
    };

    const saveWebhookAndApiKey = () => {
        saveWebhook();
    };

    const saveAIInfo = () => {
        editPublisher(user.publisher_id, {
            gpt_tagline_prompt: gptSpecificTaglinePrompt,
            gpt_cluster_tagline_prompt: gptClusterTaglinePrompt,
            gpt_department_tagline_prompt: gptDepartmentTaglinePrompt,
            gpt_homepage_tagline_prompt: gptHomepageTaglinePrompt,
            gpt_description_prompt: gptDescriptionPrompt,
            languages: languages?.map((lang) => lang.value),
            default_language: publisher?.default_language,
            description_generation: publisher?.description_generation,
            tagline_generation: publisher?.tagline_generation,
        }).then((res) => {
            if (res.success) {
                toast.success("GPT Prompt updated successfully");
            } else {
                toast.error("GPT Prompt update failed");
            }
        });
    };

    const handleLanguageChange = (selectedOption) => {
        if (!selectedOption) {
            return;
        }

        setLanguages(selectedOption);
    };

    const saveWebhook = () => {
        editPublisherWebhook(user.publisher_id, webhook).then((res) => {
            if (res.success) {
                toast.success("Webhook updated successfully");
            } else {
                toast.error("Webhook update failed");
            }
        });
    };
    useEffect(() => {
        getAllLanguages()
            .then((res) => {
                if (res.success) {
                    getPublisher(user.publisher_id)
                        .then((pub_res) => {
                            if (pub_res.success) {
                                console.log(pub_res.data);
                                setPublisher(pub_res.data);
                                setGptSpecificTaglinePrompt(pub_res.data.gpt_tagline_prompt);
                                setGptClusterTaglinePrompt(pub_res.data.gpt_cluster_tagline_prompt);
                                setGptDepartmentTaglinePrompt(pub_res.data.gpt_department_tagline_prompt);
                                setGptHomepageTaglinePrompt(pub_res.data.gpt_homepage_tagline_prompt);
                                setGptDescriptionPrompt(pub_res.data.gpt_description_prompt);
                                setGptApplicationVerificationPrompt(pub_res.data.gpt_application_verification_prompt);
                                setLanguages(res?.data?.filter((lang) => pub_res.data.languages.includes(lang.id)).map((lang) => {
                                    return {
                                        value: lang.id,
                                        label: lang.name
                                    };
                                }));
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });

                    setSystemLanguages(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        getWebhook(user.publisher_id)
            .then((res) => {
                if (res.success) {
                    setWebhook(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        getAllRoles()
            .then((res) => {
                if (res.success) {
                    setRoles(res.data);

                    const userAccessesData = res.data.find(
                        (role) => role.name === user.role
                    )?.avaliable_accesses;
                    setUserAccesses(userAccessesData);
                }
            })
            .catch((err) => {
                console.log(err);
            });

            const handleScroll = () => {
                setShowScrollToTop(window.scrollY > 300); // Show button if scrolled 300px down
            };
            window.addEventListener('scroll', handleScroll);
    
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
    }, [])

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Fragment>
            <div className="main main-app p-3 p-lg-4">
                <ol className="breadcrumb fs-sm mb-2">
                    <li className="breadcrumb-item">
                        <Link to="#">{getUserRole(user)}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                        Automations
                    </li>
                </ol>
                <h2 className="main-title">Automations</h2>
                <Nav className="nav-line mb-4">
                    {userAccesses?.includes("webhook_access") &&
                        userAccesses?.includes("api_key_access") && (
                            <Nav.Link href="#apiKeyAndWebhook">API Key & Webhook</Nav.Link>
                        )}
                    {userAccesses?.includes("ai_automations") && (
                        <Nav.Link href="#ai-automation">AI Automation</Nav.Link>
                    )}
                </Nav>
                {(userAccesses?.includes("webhook_access") ||
                    userAccesses?.includes("api_key_access")) && (
                        <Card id="apiKeyAndWebhook" className="card-settings mt-3">
                            <Card.Header>
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <Card.Title className="mb-0">
                                            Webhook & API Key
                                        </Card.Title>
                                    </div>
                                    <div className="">
                                        {userAccesses?.includes("api_key_access") && <Button
                                            variant="outline-primary"
                                            style={{ marginRight: "10px" }}
                                            size="sm"
                                            onClick={() => copyApiKey()}
                                        >
                                            Copy API Key
                                        </Button>}
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => {
                                                saveWebhookAndApiKey();
                                            }}
                                        >
                                            Save Changes
                                        </Button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body className="p-0">
                                {userAccesses?.includes("api_key_access") &&
                                    <div className="setting-item">
                                        <Row className="g-2">
                                            <Col md='5'>
                                                <h6>Api Key</h6>
                                                <p>Account API Key</p>
                                            </Col>
                                            <Col md>
                                                <Form.Control
                                                    readOnly
                                                    type="text"
                                                    placeholder=""
                                                    value={publisher?.api_key}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {userAccesses?.includes("webhook_access") &&
                                    <div className="setting-item">
                                        <Row className="g-2">
                                            <Col md='5'>
                                                <h6>Webhook URL</h6>
                                                <p>
                                                    Streamline communication between systems with webhooks
                                                </p>
                                            </Col>
                                            <Col md>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter webhook URL"
                                                    onChange={(e) => setWebhook(e.target.value)}
                                                    value={webhook}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            </Card.Body>
                        </Card>
                    )}
                {userAccesses?.includes("ai_automations") && (
                    <Card id="ai-automation" className="card-settings mt-3">
                        <Card.Header>
                            <div className="d-flex justify-content-between">
                                <div className="">
                                    <Card.Title>AI Automation</Card.Title>
                                    <Card.Text>
                                        This is paragraph about your AI auto generated taglines
                                    </Card.Text>
                                </div>
                                <div className="">
                                    <Button variant="outline-primary" size="sm" onClick={() => {
                                        saveAIInfo();
                                    }}>
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md='5'>
                                        <h6>Scrapper Proxy IP Address</h6>
                                        <p>Please add the proxy IP address to the white list</p>
                                    </Col>
                                    <Col md>
                                        <Form.Control
                                            type="text"
                                            placeholder=""
                                            disabled
                                            value="13.51.131.3"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md='5'>
                                        <h6>Tagline Generation</h6>
                                        <p>Enable or disable the tagline generation</p>
                                    </Col>
                                    <Col md>
                                        <Form.Check
                                            type="switch"
                                            className="inline-block"
                                            checked={
                                                publisher?.tagline_generation
                                            }
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    tagline_generation: e.target.checked,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {publisher?.tagline_generation &&
                                <div className="setting-item">
                                    <Row className="g-2">
                                        <Col md='5'>
                                            <h6>Specific Inventory Tagline Prompt</h6>
                                            <p>Enter the prompt for the GPT model</p>
                                        </Col>
                                        <Col md>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder=""
                                                onChange={(e) => setGptSpecificTaglinePrompt(e.target.value)}
                                                value={gptSpecificTaglinePrompt}
                                            />
                                            <PromptDescription />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {publisher?.tagline_generation &&
                                <div className="setting-item">
                                    <Row className="g-2">
                                        <Col md='5'>
                                            <h6>Cluster Inventory Tagline Prompt</h6>
                                            <p>Enter the prompt for the GPT model</p>
                                        </Col>
                                        <Col md>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder=""
                                                onChange={(e) => setGptClusterTaglinePrompt(e.target.value)}
                                                value={gptClusterTaglinePrompt}
                                            />
                                            <PromptDescription />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {publisher?.tagline_generation &&
                                <div className="setting-item">
                                    <Row className="g-2">
                                        <Col md='5'>
                                            <h6>Department Inventory Tagline Prompt</h6>
                                            <p>Enter the prompt for the GPT model</p>
                                        </Col>
                                        <Col md>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder=""
                                                onChange={(e) => setGptDepartmentTaglinePrompt(e.target.value)}
                                                value={gptDepartmentTaglinePrompt}
                                            />
                                            <PromptDescription />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {publisher?.tagline_generation &&
                                <div className="setting-item">
                                    <Row className="g-2">
                                        <Col md='5'>
                                            <h6>Homepage Inventory Tagline Prompt</h6>
                                            <p>Enter the prompt for the GPT model</p>
                                        </Col>
                                        <Col md>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder=""
                                                onChange={(e) => setGptHomepageTaglinePrompt(e.target.value)}
                                                value={gptHomepageTaglinePrompt}
                                            />
                                            <PromptDescription />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md='5'>
                                        <h6>Description Generation</h6>
                                        <p>Enable or disable the description generation</p>
                                    </Col>
                                    <Col md>
                                        <Form.Check
                                            type="switch"
                                            className="inline-block"
                                            checked={
                                                publisher?.description_generation
                                            }
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    description_generation: e.target.checked,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            {publisher?.description_generation &&
                                <div className="setting-item">
                                    <Row className="g-2">
                                        <Col md='5'>
                                            <h6>Specific Inventory Description Prompt</h6>
                                            <p>Enter the prompt for the GPT model</p>
                                        </Col>
                                        <Col md>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder=""
                                                onChange={(e) => setGptDescriptionPrompt(e.target.value)}
                                                value={gptDescriptionPrompt}
                                            />
                                            <PromptDescription />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {gptApplicationVerificationPrompt &&
                                <div className="setting-item">
                                    <Row className="g-2">
                                        <Col md='5'>
                                            <h6>Specific Application Verification Prompt</h6>
                                            <p>Enter the prompt for the GPT model</p>
                                        </Col>
                                        <Col md>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder=""
                                                onChange={(e) => setGptDescriptionPrompt(e.target.value)}
                                                value={gptDescriptionPrompt}
                                            />
                                            <PromptDescription />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md='5'>
                                        <h6>Default Language</h6>
                                        <p>Enter the default language for the taglines translation</p>
                                    </Col>
                                    <Col md>
                                        <Form.Select
                                            id="defaultLanguage"
                                            aria-label="Default select example"
                                            onChange={(e) => {
                                                setPublisher({
                                                    ...publisher,
                                                    default_language: Number(e.target.value),
                                                });
                                            }}
                                            value={publisher?.default_language}
                                        >
                                            {systemLanguages &&
                                                systemLanguages?.map((language, index) => (
                                                    <option key={index} value={language.id}>
                                                        {language.name}
                                                    </option>
                                                ))}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </div>
                            <div className="setting-item">
                                <Row className="g-2">
                                    <Col md='5'>
                                        <h6>Supported Languages</h6>
                                        <p>Enter the others languages for the taglines translation</p>
                                    </Col>
                                    <Col md>
                                        <Select
                                            id="languages"
                                            onChange={(e) => {
                                                handleLanguageChange(e);
                                            }}
                                            options={systemLanguages?.filter((language) => language.id !== publisher?.default_language)
                                                .map((language) => {
                                                    return {
                                                        label: language.name,
                                                        value: language.id,
                                                    }
                                                }
                                                )}
                                            value={languages}
                                            isSearchable={true}
                                            placeholder={""}
                                            isMulti
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card.Body>
                    </Card>
                )}
                {showScrollToTop && (
                        <Button
                            variant="primary"
                            onClick={scrollToTop}
                            className="scroll-to-top-button fs-3"
                        >
                            <i className="ri-arrow-up-line"></i>
                        </Button>
                    )}
            </div>
        </Fragment>
    )
}