import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CustomToggle from './CustomToggle'
import { getUserName } from '../../helpers/user-name'
import { getUserRole } from '../../helpers/user-role'
import { useDispatch } from 'react-redux'
import { clearUser } from '../../features/user'
import { CookiesKeyNames, useCookiesContext } from '../../contexts/CookiesContext'
import { clearAdvertiser } from '../../features/advertiser'

const accesses = ['api_key_access', 'webhook_access', 'ai_automations']

export default function UserProfileDropdown({ user, userAccesses, advertiserId }) {
    const dispatch = useDispatch()
    const userName = getUserName(user)
    const userRole = getUserRole(user)

    const { removeCookiesProperty } = useCookiesContext()
    const automationsPermission = userAccesses && accesses.some(access => userAccesses.includes(access))

    const globalConfigurationsPermission = userAccesses && userAccesses?.includes('global_variables_access')

    const isCustomer = user.role === 'CUSTOMER'

    const handleLogout = () => {
        dispatch(clearUser())
        dispatch(clearAdvertiser())
        removeCookiesProperty(CookiesKeyNames.token)
        removeCookiesProperty(CookiesKeyNames.publisherId)
        removeCookiesProperty(CookiesKeyNames.advertiserId)
    }

    const urlParams = new URLSearchParams(window.location.search);
    const advId = urlParams.get("advId");
    const pubId = urlParams.get("pubId");

    const accountSettingsPath = advId ? `/adv/settings?advId=${advId}&pubId=${pubId}` : `/pub/settings`;
    return (
        <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
            <Dropdown.Toggle as={CustomToggle}>
                <div className="avatar online">
                    <span className="avatar-initial bg-primary">{userName[0]}</span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="mt-10-f">
                <div className="dropdown-menu-body">
                    <div className="avatar avatar-xl online mb-3">
                        <span className="avatar-initial bg-primary">{userName[0]}</span>
                    </div>
                    <h5 className="mb-1 text-dark fw-semibold">{userName}</h5>
                    <p className="fs-sm text-secondary">{userRole}</p>

                    <nav className="nav">
                        {userAccesses?.includes('api_key_access') && (
                            <Link to="https://docs.revbox.co/docs/intro/?code=XrUo7zIhhU<" target="_blank">
                                <i className="ri-question-line"></i> Documentation
                            </Link>
                        )}
                    </nav>
                    <hr />
                    <nav className="nav">
                        <Link to={accountSettingsPath}>
                            <i className="ri-user-settings-line"></i> Account Settings
                        </Link>
                    </nav>
                    {globalConfigurationsPermission && !isCustomer && (
                        <nav className="nav">
                            <Link to="/pub/configurations">
                                <i className="ri-settings-4-line"></i> Global Configurations
                            </Link>
                        </nav>
                    )}
                    {automationsPermission && !isCustomer && (
                        <nav className="nav">
                            <Link to="/adv/automations">
                                <i className="ri-code-s-slash-line"></i> Automations
                            </Link>
                        </nav>
                    )}
                    <hr />
                    <nav className="nav">
                        <Link to={`/pages/signin?pub=${user.publisher_id}`} onClick={handleLogout}>
                            <i className="ri-logout-box-r-line"></i> Log Out
                        </Link>
                    </nav>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    )
}
