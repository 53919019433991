export default function HTR_Taxonomy () {
    return {
        "tooltips": {
            "ppc_cluster_column_headers": {
                "topic": "The names of the topic clusters you can bid on.",
                "status": "The current status of your campaigns.",
                "your_bid": "The current bid of your campaigns.",
                "top_bid": "The highest bid for each inventory.",
                "position": "The current position of your campaigns. The top position generates the most clicks.",
                "your_clicks": "The number of clicks your campaigns have generated over the last 30 days.",
                "action": "The action you can take on your campaigns.",
                "missed_clicks_forecast": "The missed clicks meter shows you how much room there is to optimize your campaign to deliver the most amount of clicks based on your current bid and position. If the missed clicks meter is full, this means that your campaign is optimized (ie. based on your current bid you are in the #1 position and will generate the maximum amount of clicks). If the missed clicks meter is empty, this means that based on your current bid and position you will miss out on the majority of clicks generated by the ad unit unless you update your bid to improve your position.",
            },
            "ppc_cluster_column_inlines": {
                "action": {
                    "start_bidding": "Start bidding",
                    "update_bid": "Update your bid",
                    "parent_bid_is_disactivated": "You can't bid on this cluster because the parent bid is not activated",
                },
                "topic": "This topic cluster received {views} views over the last 30 days.",
                "status": {
                    "live": "Your campaign is active. Ensure ongoing optimization to achieve the highest number of clicks.",
                    "inactive": "Your campaign is inactive. Place a bid on this topic to activate it and start generating clicks.",
                    "paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                    "pending": "Your campaign is under review and will go live soon.",
                    "inherited": "Your campaign is currently using the bid settings from the nearest active parent topic.",
                },
                "your_bid": {
                    "bid_active": "Your current bid for this topic is {bid}.",
                    "inherited_bid": "Your bid for this topic is {bid}. This bid is inherited from the nearest parent topic where you have an active bid.",
                    "no_bid": "",
                },
                "position": {
                    "is_shown": "Your campaign currently holds the #{position} position in this topic.",
                    "is_not_shown": "Your campaign is currently not displayed in this topic and is not generating clicks. Please ensure you have sufficient credits, that your monthly budget is not exhausted, and that your bid is set to least ${min_bid}. Addressing these factors will make your ad visible in this topic and start generating clicks.",
                },
                "your_clicks": "Your campaign has generated {clicks} clicks in this topic over the last 30 days.",
                "top_bid": "No tooltip",
                "missed_clicks_forecast": {
                    "optimized": "Your campaign is currently in the top position, generating the highest number of clicks in this topic. Your monthly forecast is {clicks} clicks.",
                    "no_bid": "Bid ${top_bid + 1} to claim the #1 position in this topic and generate an estimated {missed_clicks} clicks over the next 30 days.",
                    "active_bid_but_not_optimized": "Your campaign received {clicks} fewer clicks over the past 30 days compared to the top position. Increase your bid by ${increase_by} to generate {relative_opportunity}x more clicks in the next 30 days.",
                    "bid_is_paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                },
            },
            "ppc_specific_column_headers": {
                "name": "The names of the inventories you can bid on.",
                "status": "The current status of your campaigns.",
                "your_bid": "The current bid of your campaigns.",
                "position": "The current position of your campaigns. The top position generates the most clicks.",
                "top_bid": "The highest bid for each inventory.",
                "your_clicks": "The number of clicks your campaigns have generated over the last 30 days.",
                "action": "The action you can take on your campaigns.",
                "missed_clicks_forecast": "The missed clicks meter shows you how much room there is to optimize your campaign to deliver the most amount of clicks based on your current bid and position. If the missed clicks meter is full, this means that your campaign is optimized (ie. based on your current bid you are in the #1 position and will generate the maximum amount of clicks). If the missed clicks meter is empty, this means that based on your current bid and position you will miss out on the majority of clicks generated by the ad unit unless you update your bid to improve your position.",
            },
            "ppc_specific_column_inlines": {
                "action": {
                    "start_bidding": "Start bidding",
                    "update_bid": "Update your bid",
                },
                "name": "",
                "status": {
                    "live": "Your campaign is active. Ensure ongoing optimization to achieve the highest number of clicks.",
                    "inactive": "Your campaign is inactive. Place a bid on this inventory to activate it and start generating clicks.",
                    "paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                    "pending": "Your campaign is under review and will go live soon.",
                },
                "your_bid": "",
                "position": {
                    "is_shown": "Your campaign currently holds the #{position} position in this inventory.",
                    "is_not_shown": "Your campaign is currently not generating clicks in the auction. Please ensure you have sufficient credits, that your monthly budget is not exhausted, and that your campaign is active. Addressing these factors will make your ad visible in this ad unit and begin generating clicks.",
                },
                "your_clicks": "Your campaign has generated {clicks} clicks in this inventory over the last 30 days.",
                "top_bid": "",
                "missed_clicks_forecast": {
                    "optimized": "Your campaign is currently in the top position, generating the highest number of clicks in this inventory. Your monthly forecast is {clicks} clicks.",
                    "no_bid": "Bid ${top_bid_plus_one} to claim the top position in this inventory and generate an estimated {missed_clicks} clicks over the next 30 days.",
                    "active_bid_but_not_optimized": "Your campaign received {clicks} fewer clicks over the past 30 days compared to the top position. Increase your bid by ${increase_by} to generate {relative_opportunity}x more clicks in the next 30 days.",
                    "bid_is_paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                },
            },
            "ppc_branding_column_headers": {
                "name": "The names of the inventories you can bid on.",
                "status": "The current status of your campaigns.",
                "your_bid": "The current bid of your campaigns.",
                "position": "The current position of your campaigns. The top position generates the most clicks.",
                "top_bid": "The highest bid for each inventory.",
                "your_clicks": "The number of clicks your campaigns have generated over the last 30 days.",
                "action": "The action you can take on your campaigns.",
                "missed_clicks_forecast": "The missed clicks meter shows you how much room there is to optimize your campaign to deliver the most amount of clicks based on your current bid and position. If the missed clicks meter is full, this means that your campaign is optimized (ie. based on your current bid you are in the #1 position and will generate the maximum amount of clicks). If the missed clicks meter is empty, this means that based on your current bid and position you will miss out on the majority of clicks generated by the ad unit unless you update your bid to improve your position.",
            },
            "ppc_branding_column_inlines": {
                "action": {
                    "start_bidding": "Start bidding",
                    "update_bid": "Update your bid",
                },
                "name": "",
                "status": {
                    "live": "Your campaign is active. Ensure ongoing optimization to achieve the highest number of clicks.",
                    "inactive": "Your campaign is inactive. Place a bid on this inventory to activate it and start generating clicks.",
                    "paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                    "pending": "Your campaign is under review and will go live soon.",
                },
                "your_bid": "",
                "position": {
                    "is_shown": "Your campaign currently holds the #{position} position in this inventory.",
                    "is_not_shown": "Your campaign is currently not generating clicks in the auction. Please ensure you have sufficient credits, that your monthly budget is not exhausted, and that your campaign is active. Addressing these factors will make your ad visible in this ad unit and begin generating clicks.",
                },
                "your_clicks": "Your campaign has generated {clicks} clicks in this inventory over the last 30 days.",
                "top_bid": "",
                "missed_clicks_forecast": {
                    "optimized": "Your campaign is currently in the top position, generating the highest number of clicks in this inventory. Your monthly forecast is {clicks} clicks.",
                    "no_bid": "Bid ${top_bid_plus_one} to claim the top position in this inventory and generate an estimated {missed_clicks} clicks over the next 30 days.",
                    "active_bid_but_not_optimized": "Your campaign received {clicks} fewer clicks over the past 30 days compared to the top position. Increase your bid by ${increase_by} to generate {relative_opportunity}x more clicks in the next 30 days.",
                    "bid_is_paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                },
            },
            "ppc_managment_modal": {
                "main_lower_tooltip": {
                    "optimized": "Your campaign is currently in the top position, generating the highest number of clicks in this inventory. Your monthly forecast is {clicks} clicks.",
                    "no_bid": "Bid ${top_bid_plus_one} to claim the top position in this inventory and generate an estimated {missed_clicks} clicks over the next 30 days.",
                    "active_bid_but_not_optimized": "Your campaign received {clicks} fewer clicks over the past 30 days compared to the top position. Increase your bid by ${increase_by} to generate {relative_opportunity}x more clicks in the next 30 days.",
                    "bid_is_paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                },
                "30_days_views": "30 days views",
                "current_top_bid": "Current top bid",
                "minimum_bid": "Minimum bid",
                "current_position": "Current position",
                "bid_status": {
                    "live": "Your campaign is active. Ensure ongoing optimization to achieve the highest number of clicks.",
                    "inactive": "Your campaign is inactive. Place a bid on this inventory to activate it and start generating clicks.",
                    "paused": "Your campaign is paused because you've reached your monthly budget limit. To resume, please increase your budget or wait until it resets next month.",
                    "pending": "Your campaign is under review and will go live soon.",
                },
                "ppc_bid_active": {
                    "inactive": "Activate your campaign and place a bid to start generating clicks.",
                    "active": "Bidding is enabled for this campaign.",
                }
            }
        }
    }
}