import React, { useEffect, useState, memo } from "react";
import ReactApexChart from "react-apexcharts";
import { Card, Placeholder } from "react-bootstrap";

import { getPeriodAvgCPC } from "../../../services/micro";

// Function to format date in "22 Aug" format
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: '2-digit', month: 'short' }; // "22 Aug" format
  return date.toLocaleDateString('en-US', options);
};

const optionCpc = (dataLength) => ({
  chart: {
    parentHeightOffset: 0,
    type: "line",
    toolbar: { show: false },
    zoom: { enabled: false }
  },
  states: {
    hover: { filter: { type: "none" } },
    active: { filter: { type: "none" } }
  },
  colors: ["var(--column-chart)", "var(--primary)"],
  grid: {
    borderColor: "var(--border-chart)",
    padding: { top: 10, bottom: 0, left: 15, right: 15 },
    yaxis: { lines: { show: false } }
  },
  fill: {
    type: ["solid", "gradient"],
    gradient: {
      type: "vertical",
      opacityFrom: 0.35,
      opacityTo: 0.2,
      gradientToColors: ["var(--gradient-to-colors)"]
    }
  },
  stroke: { width: [0, 1.5] },
  xaxis: {
    type: "datetime",
    tickAmount: Math.min(dataLength, 10), // Dynamically set based on data length, max 10 ticks
    labels: {
      style: {
        colors: "var(--xaxis-labels)",
        fontSize: "9px"
      },
      formatter: (value) => new Date(value).getDate() // Display only the day number
    },
    axisBorder: { show: false }
  },
  yaxis: {
    show: false,
    min: 0
  },
  legend: { show: false },
  tooltip: {
    shared: true,
    intersect: false,
    x: {
      formatter: (value) => formatDate(value)
    },
    y: {
      formatter: function (value) {
        return "$" + value;
      }
    }
  }
});


function AverageCPC(props) {
  const { currentPeriod, previousPeriod } = props;
  const [response, setResponse] = useState(null);

  useEffect(() => {
    if (currentPeriod && previousPeriod) {
      if (currentPeriod[0] && currentPeriod[1] && previousPeriod[0] && previousPeriod[1]) {
        getPeriodAvgCPC(currentPeriod, previousPeriod).then((res) => {
          if (!res?.data) {
            setResponse(null);
            return;
          }
          setResponse(res.data);
        });
      }
    }
  }, [props]);

  if (response && response.periods.length > 0) {
    const parsedDate = response.periods.map(period => {
      const data = period.data.map(item => ({
        x: new Date(item[0]).getTime(),
        y: item[1]
      }));

      return {
        name: period.name,
        type: period.type,
        data: data
      };
    });

    return (
      <React.Fragment>
        <Card className="card-one">
          <Card.Body className="overflow-hidden">
            <h2 className="card-value mb-1">${response.total_avg_cpc}</h2>
            <h6 className="text-dark fw-semibold mb-1">Average CPC</h6>
            <p className="fs-xs text-secondary mb-0 lh-4">
              + Highest CPC | <b>${response.highest_cpc}</b>
            </p>

            <ReactApexChart
              series={parsedDate}
              options={optionCpc(parsedDate[0].data.length)}
              height={100}
              className="apex-chart-three"
            />
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
          <Placeholder style={{ height: 205, width: "100%" }} />
        </Placeholder>
      </React.Fragment>
    );
  }
}

export default memo(AverageCPC);