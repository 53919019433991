import React, { useEffect, useState, useCallback, memo, useMemo } from "react";
import { Card, Col, Nav, Placeholder } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { getPeriodInventoryPerformance } from "../../../services/micro";
import { AgSplittedTable } from "../../../components/AgSplittedTable";
import { InventoryTypeRenderer } from "../../inventory/components/InventoryTypeRenderer";

const customColumns = [
  {
    field: "type",
    cellRenderer: InventoryTypeRenderer,
  }
];

const colWidths = {
  ID: 100,
  name: 150,
  website: 90,
  type: 150,
  categories: 150,
  views: 150,
  bids: 150,
  freeClicks: 150,
  paidClicks: 150,
  averageCPC: 150,
  topCPC: 150,
  revenue: 150,
}

function InventoryPerformance({ currentPeriod, skin }) {
  const [response, setResponse] = useState(null);
  const [reset, setReset] = useState(0);
  const [isOneDayPicked, setIsOneDayPicked] = useState(false);

  const navigate = useNavigate();

  const colors = useMemo(() => ({
    chartPrimary: getComputedStyle(document.documentElement).getPropertyValue('--primary').trim(),
    chartSecondary: getComputedStyle(document.documentElement).getPropertyValue('--chart-secondary').trim(),
    chartTertiary: getComputedStyle(document.documentElement).getPropertyValue('--chart-tertiary').trim(),
  }), []);

  const openInventoryInsights = useCallback((id) => {
    window.open(`/pub/dashboard/inventory/insights?id=${id}`, "_blank");
  }, []);

  const getContextMenuItems = useCallback((params) => [
    {
      name: "Open " + params.node.data.name + " Insights",
      action: () => openInventoryInsights(params.node.data.ID),
    },
    "separator",
    "copy",
  ], [openInventoryInsights]);

  const onPerformanceReset = useCallback(() => {
    setReset(prev => prev + 1);
  }, []);

  const onRowClick = useCallback((event) => {
    openInventoryInsights(event.data.ID);
  }, [openInventoryInsights]);

  const optionMetrics = useMemo(() => ({
    grid: {
      show: false,
    },
    chart: {
      type: "area",
      toolbar: { show: false },
      stacked: false,
      zoom: { enabled: false },
    },
    colors: [colors.chartSecondary, colors.chartPrimary],
    stroke: { curve: "smooth", width: [2, 0.25] },
    yaxis: [
      { seriesName: "Clicks", opposite: true, labels: { show: false } },
      { seriesName: "Views", opposite: false, labels: { show: false } },
    ],
    fill: { type: "gradient", gradient: { opacityFrom: 0.5, opacityTo: 0 } },
    dataLabels: { enabled: false },
    legend: { show: false },
    xaxis: {
      type: "datetime",
      tickAmount: 6,
      labels: {
        style: { colors: "#6e7985", fontSize: "11px" },
        formatter: (val) => {
          const date = new Date(val);
          return `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`;
        },
      },
    },
  }), [colors]);

  useEffect(() => {
    if (currentPeriod) {
      if (currentPeriod[0] && currentPeriod[1]) {
        getPeriodInventoryPerformance(currentPeriod).then(
          (res) => {
            if (res) {
              setResponse(res);
            }
          }
        );
      }
    }
  }, [currentPeriod]);


  const handleOnClick = (args) => {
    const values = args[1]._cells.map((cell, index) => {
      return {
        value: cell.data,
        column: response.data.columns[index],
      };
    });

    const id = values[0].value;
    openInventoryInsights(id);
  }

  if (response && response?.data) {
    optionMetrics.yaxis[0].max = response.data.maxClicks;
    optionMetrics.yaxis[1].max = response.data.maxViews;

    const currentColumns = response.data.columns.slice(0, 5);
    const periodColumns = response.data.columns.slice(5);
    const classifiedColumns = { currentColumns, periodColumns };

    return (
      <Col xs="12">
        <Card className="card-one">
          <Card.Header>
            <Card.Title as="h6">Inventory Performance</Card.Title>
            <Nav className="nav-icon nav-icon-sm ms-auto">
              <Nav.Link href="#" onClick={onPerformanceReset}>
                <i className="ri-refresh-line"></i>
              </Nav.Link>
            </Nav>
          </Card.Header>
          <Card.Body className="p-3 p-xl-3">
            <ReactApexChart
              key={reset}
              series={response.data.periods}
              options={optionMetrics}
              type="area"
              height={250}
              className="apex-chart-two chart-container"
            />
            <AgSplittedTable
              classifiedColumns={classifiedColumns}
              getData={getPeriodInventoryPerformance}
              currentPeriod={currentPeriod}
              getContextMenuItems={getContextMenuItems}
              skin={skin}
              colWidths={colWidths}
              customColumns={customColumns}
              onRowClick={onRowClick}
            />
          </Card.Body>
        </Card>
      </Col>
    );
  } else {
    return (
      <Placeholder as={Card.Title} animation="glow" style={{ color: "var(--bs-body-color)" }}>
        <Placeholder style={{ height: 400, width: "100%" }} />
      </Placeholder>
    );
  }
}

export default memo(InventoryPerformance);
