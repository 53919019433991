import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function deleteApplication(id) {
    try {
        const token = getToken();
        if (!token) {
            throw new Error('Token not found. Authorization cannot be performed.');
        }
        axiosInstance.defaults.headers.common['Authorization'] = token;
        const response = await axiosInstance.delete(`/api/client-applications/${id}`);

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export default deleteApplication;
