import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";
import { endOfDay, startOfDay } from "date-fns";

async function getPeriodCampaignImpressionsAndClicks(currentPeriod, campaignId, current_token) {
    try {
        const token = current_token || getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/campaign/period/impressions-and-clicks', {
            current_period: [
                startOfDay(normalizeToUTC(currentPeriod[0])).getTime(),
                endOfDay(normalizeToUTC(currentPeriod[1])).getTime()
            ],
            campaign_id: campaignId
        })
        return response?.data?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getPeriodCampaignImpressionsAndClicks;
