import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function regenerateAIContent(inventory_campaign_id) {
    try {
        const token = getToken();
        const response = await axiosInstance.get(`/api/inventory-campaigns/regenerate-ai-content/${inventory_campaign_id}`, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        throw error.response.data; // Re-throw the error for further handling if needed
    }
}

export {
    regenerateAIContent
};
