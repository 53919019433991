import React, { useState, useLayoutEffect } from "react";
import { Card, Col, Nav, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getClientCampaings } from "../services/adv";
import { useNavigate } from "react-router-dom";
import { CookiesKeyNames, useCookiesContext } from "../contexts/CookiesContext";

export default function Home() {
  const [data, setData] = useState([]);
  const { cookiesData, getPathKey } = useCookiesContext();

  const advIdByCookies =
    cookiesData[getPathKey()]?.[CookiesKeyNames.advertiserId];

  const [storedAdvId, setStoredAdvId] = useState(advIdByCookies);

  const urlParams = new URLSearchParams(window.location.search);
  const refresh = urlParams.get("refresh");

  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!storedAdvId) return;

    getClientCampaings(storedAdvId)
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        console.log("Server error");
      });
  }, [storedAdvId]);

  if (refresh) {
    window.location.href = "/adv/home";
    // navigate('/adv/home?auto');
    // window.location.reload();
  }

  const nullDayFix = (nextInvoiceDate) => /null|0/.test(nextInvoiceDate) ?
    nextInvoiceDate.replace(/null|0/, '1') :
    nextInvoiceDate

  return (
    <React.Fragment>
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link href="/adv/home" to={"/adv/home"}>
                  ADV Home
                </Link>
              </li>
            </ol>
            <h2 className="main-title mb-0">Dashboard</h2>
          </div>
        </div>
        <Row className="g-3">
          {data &&
            data.map((campaign, index) => (
              <Col
                xl="12"
                key={index}
                onClick={() =>
                  navigate(`/adv/campaign/product?campaignId=${campaign.id}`)
                }
              >
                <Card className="card-one cursor-pointer">
                  <Card.Header>
                    <Card.Title as="h6">{campaign.name}</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <div className="p-2">
                      <Row className="g-3">
                        <Col sm="3">
                          {campaign.ppc_active && !campaign.ppc_paused && (
                            <h3 className="card-value fw-bold text-success mb-0">
                              ACTIVE
                            </h3>
                          )}
                          {!campaign.ppc_active && !campaign.ppc_paused && (
                            <h3 className="card-value fw-bold text-danger mb-0">
                              INACTIVE
                            </h3>
                          )}
                          {campaign.ppc_paused && (
                            <h3 className="card-value fw-bold text-warning mb-0">
                              PAUSED
                            </h3>
                          )}
                          {/* <h3 className="card-value fw-bold mb-1 text-success">{campaign.status.toUpperCase()}</h3> */}
                          <label className="card-title fw-semibold text-dark mb-1">
                            Campaign Status
                          </label>
                          <p className="mb-0 fs-xs text-secondary">
                            Your campaign will pause when you hit your budget.
                          </p>
                        </Col>
                        <Col sm="3">
                          <h3 className="card-value mb-1">
                            <span>$</span>
                            {campaign.total_spend}
                          </h3>
                          <label className="card-title fw-semibold text-dark mb-1">
                            Spend Since Last Invoice
                          </label>
                          <p className="mb-0 fs-xs text-secondary">
                            This spend will be invoiced on your next billing
                            date.
                          </p>
                        </Col>
                        <Col sm="3">
                          <h3 className="card-value mb-1">
                            {campaign.total_clicks}
                          </h3>
                          <label className="card-title fw-semibold text-dark mb-1">
                            Clicks Since Last Invoice
                          </label>
                          <p className="mb-0 fs-xs text-secondary">
                            The clicks that you received since your last
                            invoice.
                          </p>
                        </Col>
                        <Col sm="3">
                          <h3 className="card-value mb-1">
                            {nullDayFix(campaign.next_invoice_date)}
                          </h3>
                          <label className="card-title fw-semibold text-dark mb-1">
                            Next Billing Date
                          </label>
                          <p className="mb-0 fs-xs text-secondary">
                            This spend will be invoiced on your next billing
                            date.
                          </p>
                        </Col>
                        <Col sm="3" className="d-none d-sm-block"></Col>
                        <Col sm="3">
                          <h3 className="card-value mb-1">
                            <span>$</span>
                            {campaign.spend_credits}
                          </h3>
                          <label className="card-title fw-semibold text-dark mb-1">
                            Credits
                          </label>
                          <p className="mb-0 fs-xs text-secondary">
                            The remaining credits available for this campain.
                          </p>
                        </Col>
                        <Col sm="3">
                          <h3 className="card-value mb-1">
                            {campaign.total_impressions}
                          </h3>
                          <label className="card-title fw-semibold text-dark mb-1">
                            Impressions Since Last Invoice
                          </label>
                          <p className="mb-0 fs-xs text-secondary">
                            The impressions you received since your last
                            invoice.
                          </p>
                        </Col>
                        <Col sm="3">
                          <h3 className="card-value mb-1">
                            {campaign.total_active_bids}
                          </h3>
                          <label className="card-title fw-semibold text-dark mb-1">
                            Live Inventory Bids
                          </label>
                          <p className="mb-0 fs-xs text-secondary">
                            Live bids on current inventory associated to this
                            campaign.
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          <Col xl="12">
            <Button
              href="/adv/campaign/new"
              className=""
              variant="outline-primary"
              disabled
            >
              <i className="ri-add-line"></i> New Campaign
            </Button>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
