import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getPublisher(id) {
  if (id) {
    try {
      const token = getToken();
      const response = await axiosInstance.get(`/api/publishers/${id}`, {
        headers: {
          Authorization: token,
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
    //   throw error; // Re-throw the error for further handling if needed
    }
  }
}

export default getPublisher;
