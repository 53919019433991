import axiosInstance from '../../axios/instance'
import { getToken } from '../../helpers/get-token'
import { validateEmailAndUrl } from '../../helpers/validate-email-and-url'
import isAppFreeByProductName from '../micro/get_client_application_by_name'
import isAppFreeByUrl from '../micro/get_client_application_by_url'
import validateDisposableEmail from '../micro/validate_disposable_email'

async function validateApplication(values) {
    const { product_name, product_url, email } = values
    const [appByName, appByUrl] = await Promise.all([isAppFreeByProductName(product_name), isAppFreeByUrl(product_url)])

    const isProductNameTaken = appByName.data !== null
    const isProductUrlTaken = appByUrl.data !== null

    if (isProductNameTaken || isProductUrlTaken) {
        const errorMessage = isProductNameTaken
            ? `Product name "${product_name}" is already taken`
            : `Product URL "${product_url}" is already registered`
        throw new Error(errorMessage)
    }

    const disposableEmail = await validateDisposableEmail(email)
    if (disposableEmail.isDisposableEmail) {
        throw new Error('Invalid email.')
    }
    const result = validateEmailAndUrl(email, product_url)

    if (!result.valid) {
        throw new Error(result.error)
    }

    return true
}

export async function postNewApplication(values, isOnboarding = false) {
    try {
        const token = getToken()
        const publisher_id = window.location.search.split('=')[1]
        const baseUrl = '/api/client-applications'
        const url = isOnboarding ? `${baseUrl}` : `${baseUrl}/new`
        const data = { ...values, publisher_id }

        const response = await axiosInstance.post(url, data, {
            headers: {
                Authorization: token
            }
        })
        return response?.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function createNewApplication(values) {
    try {
        const isValid = await validateApplication(values)
        if (isValid) {
            const response = await postNewApplication(values)
            return response
        }
    } catch (error) {
        throw error
    }
}
