import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";
import { endOfDay, startOfDay, subDays } from "date-fns";

async function getTotalsByCluster(inventoryId, campaignId) {
    try {
        const currentPeriod = [
            startOfDay(subDays(new Date(), 30)), endOfDay(new Date())
        ];

        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/analytics/campaign/period/totals-cluster', {
            current_period: [
                normalizeToUTC(currentPeriod[0]).getTime(),
                normalizeToUTC(currentPeriod[1]).getTime()
            ],
            inventory_id: Number(inventoryId),
            campaign_id: Number(campaignId)
        })
        return response?.data.data;
    } catch (error) {
        console.log(error);
    }
}

export default getTotalsByCluster;
