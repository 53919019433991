import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getAllBillingTypes() {
    try {
        const token = getToken();
        const response = await axiosInstance.get(`/api/billing-types/all`, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function createTranasction(values) {
    try {
        const token = getToken();
        const response = await axiosInstance.post(`/api/transactions`, values, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function editBillingMethod(id, values) {
    try {
        const token = getToken();
        const response = await axiosInstance.put(`/api/billing-methods/${id}`, values, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function editTransaction(id, values) {
    try {
        const token = getToken();
        const response = await axiosInstance.put(`/api/transactions/${id}`, values, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function getAllTransactions({ search, page, column_name, column_order }) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        const params = {};
        if (search) {
            params.search = search;
        }
        if (page) {
            params.page = page;
        }
        if (column_name) {
            params.column_name = column_name;
        }
        if (column_order) {
            params.column_order = column_order;
        }

        const response = await axiosInstance.get(`/api/transactions/all`, {
            params
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function getBillingMethod(id) {
    try {
        const token = getToken();
        const response = await axiosInstance.get(`/api/billing-methods/${id}`, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function createBillingMethod(values) {
    try {
        const token = getToken();
        const response = await axiosInstance.post(`/api/billing-methods`, values, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function getTransaction(id) {
    try {
        const token = getToken();
        const response = await axiosInstance.get(`/api/transactions/${id}`, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function getAllClientInvoices({ search, page, column_name, column_order }) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        const params = {};
        if (search) {
            params.search = search;
        }
        if (page) {
            params.page = page;
        }
        if (column_name) {
            params.column_name = column_name;
        }
        if (column_order) {
            params.column_order = column_order;
        }

        const response = await axiosInstance.get(`/api/client-invoices/all`, {
           params
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

async function getClientInvoice(id) {
    try {
        const token = getToken();
        const response = await axiosInstance.get(`/api/client-invoices/${id}`, {
            headers: {
                'Authorization': token
            }
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

export {
    editTransaction,
    getAllTransactions,
    getAllClientInvoices,
    getTransaction,
    getClientInvoice,
    getBillingMethod,
    createBillingMethod,
    createTranasction,
    editBillingMethod,
    getAllBillingTypes
};
