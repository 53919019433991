import { getToken } from "../../helpers/get-token";
import { normalizeToUTC } from "../../helpers/normalize-to-utc";
import axiosInstance from "../../axios/instance";

async function getInventoriesPositions(currentPeriod) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/missed-clicks/inventories-positions', {
            current_period: [
                normalizeToUTC(currentPeriod[0]).getTime(),
                normalizeToUTC(currentPeriod[1]).getTime()
            ]
        })
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default getInventoriesPositions;
