import { delayFunc } from './delay';
import { toast } from 'react-toastify';
import { validateEmailAndUrl } from './validate-email-and-url';
import { postNewApplication } from '../services/pub/create_new_applications';
import isAppFreeByUrl from '../services/micro/get_client_application_by_url';
import isAppFreeByProductName from '../services/micro/get_client_application_by_name';
import isAppFreeByCompanyName from '../services/micro/get_client_application_by_company_name';

const steps = [
    { message: 'Creating the system tables...', delay: 2000 },
    { message: 'Creating the info columns...', delay: 2000 },
    { message: 'Preparing...', delay: 1500 }
];

const verifiedMessage = 'Congratulations! Your application has been accepted. To continue, please check your email and set up your password.';
const notVerifiedMessage = "Your application has been submitted and is pending review from our team. We'll get back to you via email in maximum 24 hours";

export const handleFormSubmit = async ({ formData, setIsLoading, setIsModalOpen, setModalMessage, setModalStatus, setValidationResults }) => {
   try {
    let isModalTriggered = false;

    const showModal = (message, status) => {
        setModalMessage(message);
        setModalStatus(status);
        setIsModalOpen(true);
        isModalTriggered = true;
    };

    const { product_url, product_name, email, company_name } = formData;

    const [isProductNameFree, isProductUrlFree, isCompanyNameFree] = await Promise.all([
        isAppFreeByProductName(product_name),
        isAppFreeByUrl(product_url),
        isAppFreeByCompanyName(company_name)
    ]);

    if (!isProductNameFree) {
        showModal(`It seems that your product name "${product_name}" already exists in our system. <br/> Please contact your team so that one of your team members can grant you access. <br/> If you believe no one from your team actually registered your product, please contact us at <a href="mailto:support@revbox.com">support@revbox.com</a>.`, 'declined');
        return;
    }

    if (!isCompanyNameFree) {
        showModal(`It seems that your company name "${company_name}" already exists in our system. <br/> Please contact your team so that one of your team members can grant you access. <br/> If you believe no one from your team actually registered your product, please contact us at <a href="mailto:support@revbox.com">support@revbox.com</a>.`, 'declined');
        return;
    }

    if (!isProductUrlFree) {
        toast.error(`Product URL "${product_url}" is already registered`);
        return;
    }

    const result = validateEmailAndUrl(email, product_url);
    if (!result.valid) {
        toast.error(result.error);
        return;
    }

    setIsLoading(true);

    for (const { delay, message } of steps) {
        await delayFunc(delay);
        if (!isModalTriggered) {
            toast.success(message);
        }
    }
    
    const submit = await postNewApplication(formData, true);

    if (submit?.success) {
        const status = submit.data.client_application_status === 1 ? 'accepted' : 'submitted';
        const isVerified = status === 'accepted';
        isModalTriggered = true;
        showModal(isVerified ? verifiedMessage : notVerifiedMessage, status);
    } else {
        toast.error('Ops! Your company was not registered. Please try again.');
    }

    setIsLoading(false);
   } catch (error) {
    console.log('error', error)
    toast.error('Server error. Please try again later.');
    setIsLoading(false);
   }
};

