import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";

export function AddItemButton({ new_btn_title, new_btn_redirect, disabled }) {
    return (
        <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>{new_btn_title}</Tooltip>}>
                <Nav.Link href={new_btn_redirect} disabled={disabled}>
                    <i className="ri-add-line"></i>
                </Nav.Link>
            </OverlayTrigger>
        </Nav>
    );
}