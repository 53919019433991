import React from "react";
import { getAllBillingMethods } from "../../services/adv";
import { Breadcrumbs } from "../inventory/components/Breadcrumbs";
import { AddItemButton } from "../inventory/components/AddItemButton";
import BasicTable from "../../components/BasicTable";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";
import { GetPubAbbr } from "../../helpers/get-pub-abbr";

const colWidth = {
  billing_contact_name: 100,
  billing_company_name: 100,
  ppc_billing_type: 100,
  billing_registered_address_country_id: 100,
  billing_vat_number: 100,
  account_id: 100,
  campaign_id: 100,
  created_at: 100,
};

const hiddenColumns = [];

const rowFields = [
  "id",
  "billing_contact_name",
  "billing_company_name",
  "ppc_billing_type",
  "billing_registered_address_country_id",
  "billing_vat_number",
  "account_id",
  "campaign_id",
  "created_at",
]

const APV_PUBLISHER_ID = 73;

export default function BillingMethods() {
    const title="Billing Methods"
    const breadcrumb="Billing Methods"
    const new_btn_title="New Billing Method"
    const edit_redirect="/pub/finance/billing/edit"

    const urlParams = new URLSearchParams(window.location.search);
    const advId = urlParams.get("advId");
    const pubId = urlParams.get("pubId");

    const breadcrumb_redirect = advId ? `/adv/finance/billing?advId=${advId}&pubId=${pubId}` : `/pub/finance/billing`;
    const new_btn_redirect = advId ? `/adv/billing/new?advId=${advId}&pubId=${pubId}` : `/pub/finance/billing/new`;

    const publisherIdByAbbr = GetPubAbbr();
    const { cookiesData, getPathKey } = useCookiesContext();
  
    const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === "dark" ? "dark" : "";
    
    return (
      <React.Fragment>
      <div className="main main-app d-flex flex-column p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} disabled={publisherIdByAbbr === APV_PUBLISHER_ID} />
        </div>

        <BasicTable getData={getAllBillingMethods} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={currentSkin}/>

        
      </div>
    </React.Fragment>
    )
}