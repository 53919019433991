import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../scss/components/_ag-table.scss";
import { getServerSideDatasourceSplitted } from "../helpers/get-serverside-datasource-splitted";
import "ag-grid-charts-enterprise";
const containerStyle = { width: "100%", height: "100%" };
const gridStyle = { minHeight: "200px", width: "100%" };
const defaultColDef = {
  flex: 1,
  width: 200,
};

export const AgSplittedTable = ({
  classifiedColumns,
  currentPeriod,
  previousPeriod,
  getData,
  setCountByStatus,
  status,
  customColumns,
  getContextMenuItems,
  skin,
  inventoryId,
  colWidths,
  onRowClick,
  getRowStyle,
  onCellMouseOver,
}) => {
  const [search, setSearch] = useState("");
  const [gridApi, setGridApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState([]);
  const [sortingParams, setSortingParams] = useState({
    columnName: null,
    sortOrder: null,
  });

  const onFilterTextBoxChanged = (e) => {
    setSearch(e.target.value);
  };

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      const datasource = getServerSideDatasourceSplitted(
        getData,
        currentPeriod,
        previousPeriod,
        colWidths,
        classifiedColumns,
        setColumnDefs,
        setCountByStatus,
        status,
        search,
        customColumns,
        inventoryId,
        sortingParams
      );

      params.api.setGridOption("serverSideDatasource", datasource);
    },
    [status]
  );

  const onSortChanged = (params) => {
    setSortingParams({
      columnName: params.columns[0].colId,
      sortOrder: params.columns[0].sort,
    });
  };

  useEffect(() => {
    if (gridApi) {
      const datasource = getServerSideDatasourceSplitted(
        getData,
        currentPeriod,
        previousPeriod,
        colWidths,
        classifiedColumns,
        setColumnDefs,
        setCountByStatus,
        status,
        search,
        customColumns,
        inventoryId,
        sortingParams
      );

      gridApi.setGridOption("serverSideDatasource", datasource);
    }
  }, [status, gridApi, search, currentPeriod, previousPeriod, sortingParams]);

  return (
    <div style={containerStyle}>
      <div
        style={gridStyle}
        className={
          skin === "dark"
            ? "ag-theme-quartz-dark ag-theme-quartz"
            : "ag-theme-quartz-light ag-theme-quartz"
        }
      >
        <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            className="input-search"
            placeholder="Search..."
            onInput={onFilterTextBoxChanged}
          />
        </div>
        <AgGridReact
          columnDefs={columnDefs}
          onRowClicked={onRowClick}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onSortChanged={onSortChanged}
          pagination={true}
          onCellMouseOver={onCellMouseOver}
          getRowStyle={getRowStyle}
          paginationPageSize={10}
          paginationPageSizeSelector={false}
          cacheBlockSize={10}
          rowModelType={"serverSide"}
          domLayout={"autoHeight"}
          allowContextMenuWithControlKey={true}
          getContextMenuItems={getContextMenuItems}
        />
      </div>
    </div>
  );
};
