import axios from 'axios';
import { logIssue } from '../mixpanel/logIssue';

let controllers = {};

const getController = () => {
  const currentPath = window.location.pathname;

  if (controllers[currentPath] && !controllers[currentPath].signal.aborted) {
    return controllers[currentPath];
  }

  const newController = new AbortController();
  controllers[currentPath] = newController;
  return newController;
};

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const controller = getController();
    config.signal = controller.signal;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  function (response) {
      return response;
  },
  function (error) {
      const responseError = error.response?.data || {};
      if (axios.isCancel(error)) {
          return Promise.resolve();
      }
      logIssue(responseError.message || 'Unknown Error', {
          error: responseError.error,
          inputParams: responseError.input_parameters,
          code: responseError.code,
          type: responseError.type,
      });
      return Promise.reject(error);
  }
);

export const cancelPreviousRequests = (previousPath) => {
  if (controllers[previousPath]) {
    controllers[previousPath].abort();
    delete controllers[previousPath];
  }
};

export default axiosInstance;
