import { getToken } from "../../helpers/get-token";
import axiosInstance from "../../axios/instance";

async function createUser(values) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;
        const response = await axiosInstance.post(process.env.REACT_APP_HUB_URL + '/api/users', values)
        return response?.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error.response.data.message);
        } else {
            throw new Error("You don't have permission to create this record!");
        }
    }
}

export default createUser;
