import React, { useEffect, useState, useLayoutEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { startOfDay, endOfDay, addDays, subDays } from "date-fns";
import AdvertiserTotals from "./campaign/CampaignTotals";
import CampaignImpressionsAndClicks from "./campaign/CampaignImpressionsAndClicks";
import CampaignInventoryStats from "./campaign/CampaignInventoryStats";
import CampaignMap from "./campaign/CampaignMap";
import { verifyCustomerToken } from "../../services/micro";
import { modules } from "../../modules";
import { blankPageMap } from "../../modules/maps";
import { getCampaign } from "../../services/adv";
import { useSelector } from "react-redux";
import {
  CookiesKeyNames,
  isCookiesEnabled,
  useCookiesContext,
} from "../../contexts/CookiesContext";
import Cookies from "universal-cookie";

const PUBLISHER = "PUBLISHER";
const HTR_PUBLISHER_ID = 67;
const HTR_STAGE_PUBLISHER_ID = 10;

export default function AdvertiserAnalytics({ isIframe }) {
  const location = useLocation();
  const [campaignId, setCampaignId] = useState(
    new URLSearchParams(location.search).get("campaignId")
  );

  const user = useSelector((state) => state.user);
  const advertiser = useSelector((state) => state.advertiser);
  const userRole = user?.role;
  const [publisherId, setPublisherId] = useState(null);
  const token = new URLSearchParams(location.search).get("token");
  const [campaign, setCampaign] = useState({});
  const { updateCookiesProperty, cookiesData, getPathKey } =
    useCookiesContext();

  const [currentPeriod, setCurrentPeriod] = useState([null, null]);
  const [previousPeriod, setPreviousPeriod] = useState([null, null]);

  useLayoutEffect(() => {
    getCampaign(campaignId).then((res) => {
      setCampaign(res.data);
    });
  }, [campaignId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setCampaignId(urlParams.get("campaignId"));
  }, [location.search]);

  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);
  const [isTokenVerified, setIsTokenVerified] = useState(null);

  const loadDateRange = () => {
    if (token) {
      const begin_of_day = startOfDay(new Date());
      const end_of_day = endOfDay(new Date());
  
      // Fallback to the last 30 days if nothing is stored in cookies
      const defaultCurrentPeriod = [
        addDays(begin_of_day, -29),
        end_of_day,
      ];
      const defaultPreviousPeriod = [
        addDays(begin_of_day, -59),
        addDays(end_of_day, -29),
      ];
  
      return {
        currentPeriod: defaultCurrentPeriod,
        previousPeriod: defaultPreviousPeriod,
      };
    } else {
      const storedCurrentPeriod = isCookiesEnabled
        ? cookiesData[getPathKey()]?.[CookiesKeyNames.currentPeriod]
        : JSON.parse(cookiesData[getPathKey()]?.[CookiesKeyNames.currentPeriod]);
      const storedPreviousPeriod = isCookiesEnabled
        ? cookiesData[getPathKey()]?.[CookiesKeyNames.previousPeriod]
        : JSON.parse(cookiesData[getPathKey()]?.[CookiesKeyNames.previousPeriod]);

      // Parse dates from cookies, if available
      if (storedCurrentPeriod && storedPreviousPeriod) {
        return {
          currentPeriod: storedCurrentPeriod.map((date) => new Date(date)),
          previousPeriod: storedPreviousPeriod.map((date) => new Date(date)),
        };
      }

      // Fallback to the last 30 days if nothing is stored in cookies
      const begin_of_day = startOfDay(new Date());
      const end_of_day = endOfDay(new Date());
  
      // Fallback to the last 30 days if nothing is stored in cookies
      const defaultCurrentPeriod = [
        addDays(begin_of_day, -29),
        end_of_day,
      ];
      const defaultPreviousPeriod = [
        addDays(begin_of_day, -59),
        addDays(end_of_day, -29),
      ];

      return {
        currentPeriod: defaultCurrentPeriod,
        previousPeriod: defaultPreviousPeriod,
      };
    }
  };

  useEffect(() => {
    if (token) {
      verifyCustomerToken(token).then((response) => {
        if (response && response.success) {
          updateCookiesProperty(CookiesKeyNames.token, token);
          setPublisherId(response?.customer?.publisher_id);

          const pubId =
            cookiesData[getPathKey()]?.[CookiesKeyNames.publisherId];
          if (!pubId) {
            updateCookiesProperty(
              CookiesKeyNames.publisherId,
              response?.customer?.publisher_id
            );
            window.location.reload();
          }

          setIsTokenVerified(true);
        } else {
          setIsTokenVerified(false);
        }
      });
    }
  }, [token]);

  useEffect(() => {
    const { currentPeriod, previousPeriod } = loadDateRange();
    setCurrentPeriod(currentPeriod);
    setPreviousPeriod(previousPeriod);
  }, []);

  useEffect(() => {
    if (
      currentPeriod &&
      currentPeriod.length === 2 &&
      currentPeriod[0] &&
      currentPeriod[1]
    ) {
      updateCookiesProperty(CookiesKeyNames.currentPeriod, currentPeriod);
      updateCookiesProperty(CookiesKeyNames.previousPeriod, previousPeriod);
    }
  }, [currentPeriod, previousPeriod]);

  const handleOpenDateRangePicker = () => {
    setIsDateRangePickerOpen(true);
  };

  const handleDateRange = (e) => {
    const curBegin = new Date(e[0]);
    const curEnd = new Date(e[1]);

    curEnd.setHours(23, 59, 59, 999);

    const diff = curEnd.getTime() - curBegin.getTime() + 1;

    const prevBegin = new Date(curBegin.getTime() - diff);
    const prevEnd = new Date(curEnd.getTime() - diff);

    setPreviousPeriod([prevBegin, prevEnd]);
    setCurrentPeriod([curBegin, curEnd]);
  };

  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode]=== "dark"
    ? "dark"
    : "";

  if (isIframe && isTokenVerified === false) {
    return modules(10, blankPageMap);
  }

  if (isIframe && !campaignId) {
    return modules(10, blankPageMap);
  }

  if ((isIframe && isTokenVerified === true) || !isIframe) {
    let customStyle = {};

    if (
      publisherId === HTR_PUBLISHER_ID ||
      publisherId === HTR_STAGE_PUBLISHER_ID
    ) {
      customStyle = {
        paddingLeft: "46px",
        paddingRight: "46px",
        paddingTop: "15px",
        paddingBottom: "15px",
      };
    }

    return (
      <React.Fragment>
        <div
          className={isIframe ? "" : "main main-app p-3 p-lg-4"}
          style={isIframe ? customStyle : {}}
        >
          <div
            className={
              isIframe
                ? "d-flex align-items-center justify-content-end mb-4"
                : "d-flex align-items-center justify-content-between mb-4"
            }
          >
            {isIframe ? (
              <></>
            ) : (
              <div>
                <ol className="breadcrumb fs-sm mb-1">
                  <li className="breadcrumb-item">
                    <Link href="/adv/home" to={"/adv/home"}>
                      ADV Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">{campaign?.name}</li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Campaign Analytics
                  </li>
                </ol>
                <h2 className="main-title mb-0">Campaign Analytics</h2>
              </div>
            )}
          </div>

          <Row className="g-3">
            <Col md="12" xl="12" className="d-flex align-items-center">
              <div className="me-3 fs-11">Current Data</div>
              <div className="flex-grow-1 border-bottom"></div>
            </Col>
            <AdvertiserTotals
              currentPeriod={currentPeriod}
              previousPeriod={previousPeriod}
              campaignId={campaignId}
            />
            <Col md="12" xl="12" className="d-flex align-items-center">
              <div className="me-3 fs-11">Period Stats</div>
              <div className="flex-grow-1 border-bottom"></div>
            </Col>
            <Col md="12" xl="12">
              <Card className="card-one">
                <CampaignImpressionsAndClicks
                  currentPeriod={currentPeriod}
                  campaignId={campaignId}
                  handleOpenDateRangePicker={handleOpenDateRangePicker}
                  isDateRangePickerOpen={isDateRangePickerOpen}
                  setIsDateRangePickerOpen={setIsDateRangePickerOpen}
                  handleDateRange={handleDateRange}
                />
                <CampaignInventoryStats
                  currentPeriod={currentPeriod}
                  previousPeriod={previousPeriod}
                  campaignId={campaignId}
                  isIframe={isIframe}
                  skin={currentSkin}
                  view={isIframe || advertiser.id ? "ADV" : "PUB"}
                />
              </Card>
            </Col>

            {isIframe || userRole !== PUBLISHER || advertiser.id ? (
              <></>
            ) : (
              <CampaignMap
                currentPeriod={currentPeriod}
                previousPeriod={previousPeriod}
                campaignId={campaignId}
              />
            )}
          </Row>


        </div>
      </React.Fragment>
    );
  }
}
