import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Table, Nav, Button } from 'react-bootstrap';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getClientInvoice } from '../../services/finance';
import getAllCountires from '../../services/adv/get_all_countires';
import { logosModule } from "../../modules/logos";
import { GetPubAbbr } from "../../helpers/get-pub-abbr";
import { getCampaign } from '../../services/adv';
import { CookiesKeyNames, isCookiesEnabled } from '../../contexts/CookiesContext';
import Cookies from 'universal-cookie';

const INVOICE_PREPAYMENT_BILLING_TYPE = 1;
const INVOICE_POSTPAYMENT_BILLING_TYPE = 2;
const CREDIT_CARD_PREPAYMENT_BILLING_TYPE = 3;
const CREDIT_CARD_POSTPAYMENT_BILLING_TYPE = 4;

class Invoice extends React.Component {
    render() {
        const cookies = new Cookies();
        let skinMode = (isCookiesEnabled ?
            cookies.get(CookiesKeyNames.sidebarSkin) :
            localStorage.getItem(CookiesKeyNames.sidebarSkin)) || 'default';
        const publisherIdByAbbr = GetPubAbbr();

        const publisher_id = publisherIdByAbbr || (isCookiesEnabled ? cookies.get(CookiesKeyNames.publisherId) : localStorage.getItem(CookiesKeyNames.publisherId));
        const siteLogo = logosModule(publisher_id, skinMode, {
            width: 200,
            height: 50,
        });

        const { invoice, countries, campaign } = this.props;

        const dueDate = new Date(invoice.due_date).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

        const dateOfIssue = new Date(invoice.date_of_issue || dueDate).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

        const customerName = invoice.first_name + ' ' + invoice.last_name;
        const customerEmail = invoice.user_email;
        const customerCompany = invoice.invoice_account_name;
        const invoiceCountry = countries?.data?.find(country => country.id === invoice.invoice_address_country_id)?.name;
        const address = invoice.invoice_address;
        const invoiceNumber = invoice.invoice_no;

        const items = [
            { name: 'PPC Campaign Spend', price: invoice.amount },
        ];

        const subtotal = items.reduce((acc, item) => acc + item.price, 0);
        const vatFee = invoice.vat_fee;
        const vatAmount = invoice.vat_amount;
        const convertedAmount = invoice.converted_amount;
        const vatTotal = vatAmount;
        const total = subtotal + vatTotal;

        const bankData = {
            bankName: 'DemoBank Inc.',
            accountName: 'Revbox Ltd.',
            accountNumber: '1234 5678 90',
            sortCode: '12-34-56',
            iban: 'GB12DEMO1234567890',
            swift: 'DEMOGB2L'
        };

        const {
            bankName, accountName, accountNumber, sortCode, iban, swift
        } = bankData;

        const widthOfInvoiceCode = document.getElementById('invoice-code')?.offsetWidth;

        return (
            <Container className="invoice">
                <Row className="logo-section">
                    <Col className="text-left mb-4 w-100 invoice-logo">
                        {/* <object type="image/svg+xml" data={siteLogo} className="w-100 invoice-logo" aria-label="svg image"></object> */}
                        {siteLogo}
                    </Col>
                </Row>

                <Row className="business-info">
                    <Col md={12}>
                        <table className='w-100'>
                            <tbody>
                                <tr>
                                    <td className='text-start'>
                                        <p><strong className='fs-3'>Revbox Ltd</strong></p>
                                        <div className='address'>
                                            <p className='fst-italic'>info@revbox.co</p>
                                            <p>20-22 Wenlock Road</p>
                                            <p>N1 7GU London</p>
                                            <p>United Kingdom</p>
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <p>
                                            <strong className='text-uppercase fs-3' id='invoice-code'>Invoice #{invoiceNumber}</strong>
                                        </p>
                                        <p><span className='text-uppercase'>Issue Date:</span> {dateOfIssue}</p>
                                        {
                                            !invoice.paid && <p><span className='text-uppercase'>Due Date:</span> {dueDate}</p>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>

                <Row className="customer-details">
                    <Col>
                        <h4
                            className='mb-3'
                            style={{
                                color: '#000',
                            }}
                        >Bill To</h4>
                        <div
                            className='item-wrapper'
                            style={{
                                height: '1px',
                                marginTop: '-20px',
                                marginBottom: '20px',
                                background: "#000"
                            }}
                        />
                        <p><strong>{customerCompany}</strong></p>
                        <div className='address'>
                            <p>c/o {customerName}</p>
                            <p className='fst-italic'>{customerEmail}</p>
                            <p style={{
                                maxWidth: '300px',
                            }}>{address}</p>
                            <p>{invoiceCountry}</p>
                        </div>
                    </Col>
                </Row>

                <Row className="items-section mb-0">
                    <Col>
                        <h4
                            style={{
                                color: '#000',
                            }}
                        >Campaign Spend</h4>
                        <div
                            className='item-wrapper'
                            style={{
                                height: '1px',
                                background: "#000",
                            }}
                        />
                        <div className='item-wrapper'>
                            <Table striped hover className='mb-0'>
                                <thead>
                                    <tr>
                                        <th>Item Name</th>
                                        <th width="100">Price</th>
                                        <th width="100">VAT ({vatFee}%)</th>
                                        <th width="160" className='text-end'>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, idx) => (
                                        <tr key={idx}>
                                            <td>{item.name}</td>
                                            <td>${item.price.toFixed(2)}</td>
                                            <td>${(vatAmount).toFixed(2)}</td>
                                            <td className='text-end'>${(total).toFixed(2)}</td>
                                        </tr>
                                    ))}
                                    {/* <tr>
                                        <td colSpan="3"><strong>Subtotal</strong></td>
                                        <td className='text-end'>${subtotal.toFixed(2)}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td colSpan="3">VAT</td>
                                        <td className='text-end'>${vatTotal.toFixed(2)}</td>
                                    </tr> */}
                                    <tr>
                                        <td colSpan="3"><strong>Grand Total</strong></td>
                                        <td className='text-end'><strong>${total.toFixed(2)}</strong></td>
                                    </tr>
                                    <tr></tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
                <Row className="payment-details mt-4">
                    <Col>
                        <h4
                            style={{
                                color: '#000',
                            }}
                        >Payment Details</h4>
                        <div
                            className='item-wrapper'
                            style={{
                                height: '1px',
                                marginTop: '-20px',
                                marginBottom: '20px',
                                background: "#000"
                            }}
                        />
                        <p>Payment Status: <strong className='text-uppercase'>{invoice.paid ? "Paid" : "Unpaid"}</strong></p>
                        <p>
                            <table className='mb-0'>
                                <tbody>
                                    <tr>
                                        <td>Currency conversion at date of transaction @ USD1.00 = {invoice.currency} {convertedAmount}</td>
                                    </tr>
                                    <tr>
                                        <td>USD VAT / Tax @ {(vatFee).toFixed(0)}%: {(vatAmount).toFixed(2)} | {invoice.currency} VAT / Tax @ {(vatFee).toFixed(0)}%: {(vatTotal * convertedAmount).toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td>USD Invoice Total: {total.toFixed(2)} | {invoice.currency} Invoice Total: {(total * convertedAmount).toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </p>
                        {
                            invoice.paid &&
                            <p className='dashed-seperator'>Thank you for your payment.</p>
                        }
                        {
                            !invoice.paid &&
                            <p className='dashed-seperator'>We are waiting for your payment.</p>
                        }
                        {
                            (campaign?.ppc_billing_type === INVOICE_PREPAYMENT_BILLING_TYPE ||
                                campaign?.ppc_billing_type === INVOICE_POSTPAYMENT_BILLING_TYPE) &&
                            <p className='dashed-seperator'>Please make payment of USD{total.toFixed(2)} to the following account using the reference below:</p>
                        }
                        {
                            (campaign?.ppc_billing_type === INVOICE_PREPAYMENT_BILLING_TYPE ||
                                campaign?.ppc_billing_type === INVOICE_POSTPAYMENT_BILLING_TYPE) &&
                            <p><span className='text-uppercase'>Bank: {bankName}</span>  | <span className='text-uppercase'>Account Name:</span> {accountName} | <span className='text-uppercase'>Account Number:</span> {accountNumber} | <span className='text-uppercase'>Sort Code:</span> {sortCode} | <span className='text-uppercase'>IBAN:</span> {iban} | <span className='text-uppercase'>BIC/SWIFT:</span> {swift} | <span className='text-uppercase'>Reference:</span> {invoiceNumber}</p>
                        }
                    </Col>
                </Row>
                <div style={{
                    height: '50px',
                    width: '100%',
                }}></div>
            </Container>
        );
    }
}

export default function InvoicePreview() {
    const urlParams = new URLSearchParams(window.location.search);
    const values = Object.fromEntries(urlParams.entries());
    const invoice_id = Number(values.id);

    const [invoice, setInvoice] = useState({});
    const [countries, setCountries] = useState([]);
    const [campaign, setCampaign] = useState({});

    useEffect(() => {
        getClientInvoice(invoice_id).then(res => {
            setInvoice(res.data);

            getCampaign(res.data.campaign_id).then(campaign => {
                setCampaign(campaign.data);
            }).catch(e => {
                console.log('Error fetching campaign', e);
            });
        }).catch(e => {
            console.log('Error fetching invoice', e);
        });

        getAllCountires().then(res => {
            setCountries(res);
        }).catch(e => {
            console.log('Error fetching countries', e);
        });
    }, []);

    const title = 'Invoice Preview';
    const breadcrumb = 'Invoices';
    const redirect = '/pub/finance/invoices';

    const component = useRef();

    const onPrint = useReactToPrint({
        content: () => component.current,
    });

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <Nav.Item>
                                <Nav.Link
                                    className="text-primary"
                                    href="/pub/dashboard/revenue"
                                    to="/pub/dashboard/revenue"
                                >
                                    PUB Home
                                </Nav.Link>
                            </Nav.Item>
                            <span className="mx-2">/</span>
                            <Nav.Item>
                                <Nav.Link className="text-primary" href={redirect}>
                                    {breadcrumb}
                                </Nav.Link>
                            </Nav.Item>
                            <span className="mx-2">/</span>
                            <Nav.Item>
                                <Nav.Link href="#">Preview</Nav.Link>
                            </Nav.Item>
                        </ol>
                        <h2 className="main-title mb-0">{title}</h2>
                    </div>
                    <Col md="6">
                        <div className="d-flex align-items-center justify-content-end m-4">
                            <Button
                                onClick={() => onPrint()}
                                variant="primary"
                                size="sm"
                            >
                                Download PDF
                            </Button>
                        </div>
                    </Col>
                </div>
                <Row className="g-3 justify-content-center">
                    {
                        invoice.id &&
                        <Invoice
                            ref={component}
                            invoice={invoice}
                            countries={countries}
                            campaign={campaign}
                        />
                    }
                </Row>
            </div>
        </React.Fragment>
    );
}
