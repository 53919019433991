import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getPublisherInventories({ search, page, column_name, column_order, status }) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        const params = {};
        if (search) {
            params.search = search;
        }
        if (page) {
            params.page = page;
        }
        if (column_name) {
            params.column_name = column_name;
        }
        if (column_order) {
            params.column_order = column_order;
        }
        if (status) {
            params.type = status;
        }

        const response = await axiosInstance.get(process.env.REACT_APP_HUB_URL + '/api/publisher-inventory/all', {
            params
        });

        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

export default getPublisherInventories;
