import React from "react";
import { getAllTransactions } from "../../services/finance";
import { Breadcrumbs } from "../inventory/components/Breadcrumbs";
import { AddItemButton } from "../inventory/components/AddItemButton";
import BasicTable from "../../components/BasicTable";
import { CookiesKeyNames, useCookiesContext } from "../../contexts/CookiesContext";

const colWidth = {
  amount: 100,
  account_id: 100,
  campaign_id: 100,
  transaction_status_id: 100,
  transaction_type_id: 100,
  date_of_issue: 100,
  invoice_address_country_id: 100,
  vat_number_valid: 100,
  paid: 100,
  void: 100,
};

const hiddenColumns = [];

const rowFields = [
  "id",
  "amount",
  "account_id",
  "campaign_id",
  "transaction_status_id",
  "transaction_type_id",
  "date_of_issue",
  "invoice_address_country_id",
  "vat_number_valid",
  "paid",
  "void",
]

export default function Transactions() {
  const title="Transactions"
  const breadcrumb="Finance"
  const new_btn_title="New Transaction"
  const edit_redirect="/pub/finance/transaction/edit"
  const breadcrumb_redirect="/pub/transaction"
  const { cookiesData, getPathKey } = useCookiesContext();
  
  const currentSkin = cookiesData[getPathKey()]?.[CookiesKeyNames.skinMode] === "dark" ? "dark" : "";

  const publisher_id = cookiesData[getPathKey()]?.[CookiesKeyNames.publisher_id];
  let new_btn_redirect = "/pub/finance/transaction/new";

  if (publisher_id == 10 || publisher_id == 67) {
    new_btn_redirect = null;
  }

  return (
    <React.Fragment>
      <div className="main main-app d-flex flex-column p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Breadcrumbs breadcrumb={breadcrumb} breadcrumb_redirect={breadcrumb_redirect} title={title} />
          <AddItemButton new_btn_title={new_btn_title} new_btn_redirect={new_btn_redirect} />
        </div>

        <BasicTable getData={getAllTransactions} url={edit_redirect} hiddenColumns={hiddenColumns} colWidth={colWidth} rowFields={rowFields} skin={currentSkin}/>

        
      </div>
    </React.Fragment>
  )
}