import axiosInstance from "../../axios/instance";

async function login(email, password) {
  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_HUB_URL + "/api/auth/login",
      {
        email: email,
        password: password,
      }
    );

    return response?.data;
  } catch (error) {
    console.log(error);
  }
}

export default login;
