import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function getTableStructure(table_name, publisher_id) {
    try {
        const token = getToken();
        axiosInstance.defaults.headers.common['authorization'] = token;

        if (!table_name) {
            throw new Error("Table name is required");
        }
        
        const table_info_response = await axiosInstance.get(
            `${process.env.REACT_APP_HUB_URL}/api/table-structure?table_name=${table_name}`
        );

        if (!table_info_response.data) {
            throw new Error("Table info not found");
        }

        return table_info_response.data;
    } catch (error) {
        console.log(error);
    }
}

export default getTableStructure;
