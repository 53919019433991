import axiosInstance from "../../axios/instance";
import { getToken } from "../../helpers/get-token";

async function createNewInventory(values) {
    try {
        const token = getToken();
        const response = await axiosInstance.post('/api/publisher-inventory/', values, {
            headers: {
                'Authorization': token
            }
        });
        return response?.data;
    } catch (error) {
        console.log(error);
        throw error; // Re-throw the error for further handling if needed
    }
}

export default createNewInventory;
