import axiosInstance from "../../axios/instance";

async function verifyCustomerToken(token) {
    try {
        const response = await axiosInstance.get(process.env.REACT_APP_HUB_URL + '/api/auth/verify-customer-token/' + token)
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}

export default verifyCustomerToken;
